//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Menu Open
//  This is the function that is called to animate the menu open
//
//==================================================================================================

/**
 * Function used to open the menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Open the menu
 * openMenu(megaMenu);
 *
 * // Set Active to true
 * megaMenu.active = true;
 */
export const openMenu = (instance) => {
    // Cache the instance
    const megaMenu = instance;

    // Remove active from the trigger
    megaMenu.trigger.setAttribute("data-active", "");

    // Create a new timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Animate the menu to visible
    /*
        This is added to set the menu to offset to fix an issue with
        translateY animations in chrome.
    */
    timeline.add({
        begin: function() {
            // Set the menu element to active
            megaMenu.element.setAttribute("data-active", "");

        },
        targets: megaMenu.element,
        duration: 0,
        translateY: "-100%"
    });

    // Open the menu
    timeline.add({
        targets: megaMenu.element,
        translateY: 0,
        duration: 300,
        easing: "linear"
    });

    // Play the timeline
    timeline.play();

};