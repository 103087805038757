//==================================================================================================
//
//	Error handling
//  The last argument passed to the constructor should always be the callback
//
//==================================================================================================
import { toArray } from "helpers/shared/toArray";


//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * Error handling object which fires a callback
 * @constructor
 * @property {Array} args - Argument list passed to the constructor, converted to an array
 * @property {Function} callback - By convention if the last argument received is a function, the function is called with the argument list applied
 * @example
 * if (badThingsHappen) {
 *   return new CatchError(arguments, function catchErrorCallback() {
 *      let error = this;
 *      console.warn("BAD THINGS HAPPENED");
 *      console.warn(error);
 *   });
 * }
 */
export const CatchError = function() {
    const error = this;
    const lastarg = arguments[arguments.length - 1];

    error.args = toArray(arguments).slice(0, arguments.length - 1);
    error.callback = typeof lastarg === "function" ? lastarg : undefined;

    //  Fire our callback
    if (error.callback !== undefined) {
        error.callback.apply(error, error.args);
    }
};