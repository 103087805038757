//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Menu Closed
//  This is the function that is called to animate the menu closed
//
//==================================================================================================

/**
 * Function used to close the menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Close the menu
 * closeMenu(megaMenu);
 *
 * // Set active to false
 * megaMenu.active = false;
 */
export const closeMenu = (instance) => {
    // Cache the instance
    const megaMenu = instance;

    // Create a new timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Close the menu
    timeline.add({
        targets: megaMenu.element,
        translateY: "-100%",
        duration: megaMenu.hardReset ? 0 : 300,
        easing: "linear",
        complete: function() {
            megaMenu.element.removeAttribute("data-active");
        }
    });

    // Remove active from the trigger
    megaMenu.trigger.removeAttribute("data-active");

    // Play the timeline
    timeline.play();
};