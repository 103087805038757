//==================================================================================================
//
//  Drop Down List
//
//==================================================================================================

/**
 * Used to add custom drop down functionality to the page
 * @constructor
 * @param {HTMLElement} element - The dropdown element
 * @property {HTMLElement} element - The dropdown element.
 * @property {HTMLElement} list - The list element.
 * @property {Boolean} active - The current open state.
 * @example
 * // Get all drop down elements
 * const dropDownElements = toArray(document.querySelectorAll("[data-drop-down]"));
 *
 * // If the elements exist
 * if(dropDownElements.length > 0) {
 *
 *      // Loop through each element
 *      dropDownElements.forEach((currentElement) => {
 *          // Create new drop down list instance
 *          const dropDown = new DropDownList(currentElement);
 *      });
 *
 * }
 */
export const DropDownList = function (element) {
    // Cache instance
    const dropDown = this;

    // Set properties
    dropDown.element = element;
    dropDown.list = element.querySelector("[data-drop-down-list]");
    dropDown.active = false;

    // Initiate
    dropDown.init();
};

/**
 * Used to initiate the drop down
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.init = function() {
    // Cache instance
    const dropDown = this;

    // Attach events
    dropDown.attachEvents();
};

/**
 * Used to open the drop down
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.open = function() {
    // Cache instance
    const dropDown = this;

    // Update status
    dropDown.active = true;

    // Update the UI
    dropDown.updateUI();
};

/**
 * Used to close the drop down
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.close = function(){
    // Cache instance
    const dropDown = this;

    // Update status
    dropDown.active = false;

    // Update the UI
    dropDown.updateUI();
};

/**
 * Used to toggle the drop down open / close
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.toggle = function() {
    // Cache instance
    const dropDown = this;

    // Chekc if the drop down is closed
    if(!dropDown.active) {
        // Open the dropdown
        dropDown.open();
    }
    else {
        // Close the dropdown
        dropDown.close();
    }
};

/**
 * Used to attach events to the drop down
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.attachEvents = function() {
    // Cache instance
    const dropDown = this;
    // Add click event to element
    dropDown.element.addEventListener("click", (event) => {
        // Prevent Default
        //event.preventDefault();
        // Toggle the drop Down
        dropDown.toggle();
    });

    dropDown.list.addEventListener("mouseleave", () => {
        // Close the drop down
        dropDown.close();
    });
};

/**
 * Used to update the ui of the drop down
 * @function
 * @memberOf DropDownList
 */
DropDownList.prototype.updateUI = function() {
    // Cache instance
    const dropDown = this;

    // If active
    if(dropDown.active) {
        // Set to visible
        dropDown.element.setAttribute("aria-hidden", "false");

    }
    else {
        // Set to not visible
        dropDown.element.setAttribute("aria-hidden", "true");
    }

};