//==================================================================================================
//
//	Check whether an element is a child of another
//
//==================================================================================================

if (HTMLElement.isChildOf === undefined) {

    /**
     * Check whether an HTMLElement is the child of another
     * @function external:HTMLElement#isChildOf
     * @param {HTMLElement} node - Node to be checked as a child
     * @returns {Boolean}
     * @example
     * let element = document.querySelector(".button");
     * let container = document.querySelector(".container");
     * if (element.isChildOf(container)) {
     *  //  Do something
     * }
     */
    HTMLElement.prototype.isChildOf = function(node) {
        const element = this;
        let result = false;
        let parent;

        while(parent && result !== true) {
            parent = element.parentNode;
            if (parent === node) {
                result = true;
            }
        }

        return result;
    };

    /**
     * @function external:SVGElement#isChildOf
     * @see external:HTMLElement#isChildOf
     */
    SVGElement.prototype.isChildOf = HTMLElement.prototype.isChildOf;

}