//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Sub Menu Out
//  This is the function that is called to animate the sub menu of a tray to a closed state
//
//==================================================================================================

/**
 * Function used to animate out a desired  sub menu
 * @function
 * @ignore
 * @param {Object} instance - The Mega nav instance
 * @param {HTMLElement} trayDetails - The tray details object.
 * @param {HTMLElement} SubMenuData - The sub menu to be animated in.
 * @param {Function} callback - Optional callback.
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Create a new promise
 * const closeMenu = new Promise((resolve, reject) => {
 *      // Animate Sub Menu Out
 *      animateSubMenuOut(megaMenu, megaMenu.activeSubMenu.tray, megaMenu.activeSubMenu.menu, callback);
 * });
 *
 * // Once closed
 * closeMenu.then(() => {
 *      // Remove active item
 *      megaMenu.activeSubMenu = undefined;
 * });
 *
 */
export const animateSubMenuOut = (instance, trayDetails, subMenuData, callback) => {
    // Cache Instance
    const megaMenu = instance;

    // Set Play to active
    megaMenu.activeTransition = true;

    // Set active sub menu
    megaMenu.activeSubMenu = undefined;

    // Create timeline instance
    const timeline = anime.timeline({
        autoplay: false
    });


    // Animate In Sub Nav Columns
    timeline.add({
        targets: subMenuData.subNavColumns,
        translateX: "50%",
        duration: (megaMenu.hardReset === true) ? 0 : 200,
        easing: "linear",
    });


    // Animate Out Sub Menu Items
    timeline.add({
        targets: subMenuData.element,
        opacity: 0,
        duration: (megaMenu.hardReset === true) ? 0 : 300,
        easing: "linear",
        offset: (megaMenu.hardReset === true) ? 0 : "-=280",
        complete: function() {
            // Set column to display none
            subMenuData.element.style.display = "none";
        }
    });

    // Define sub menu size (+ 120 for padding 60px top and bottom);
    const subMenuSize = subMenuData.element.offsetHeight + 120;

    // Check if a resize is required for the tray
    if(subMenuSize > megaMenu.activeItem.trayOriginalSize) {
        timeline.add({
            targets: trayDetails.trayElement,
            height: megaMenu.activeItem.trayOriginalSize,
            easing: "linear",
            offset: (megaMenu.hardReset === true) ? 0 : 0,
            duration: (megaMenu.hardReset === true) ? 0 : 300
        });
    }


     // Fade Out Promo Column
     timeline.add({
        begin: function() {
            // Set the display settings on the promo column
            trayDetails.columns.promoColumn.style.display = "block";
        },
        targets: trayDetails.columns.promoColumn,
        opacity: 1,
        duration: (megaMenu.hardReset === true) ? 0 : 300,
        easing: 'linear'
    });

    // Animate Arrow
    timeline.add({
        targets: subMenuData.trigger,
        translateX: "0px",
        duration: (megaMenu.hardReset === true) ? 0 : 80,
        easing: 'linear',
        offset: (megaMenu.hardReset === true) ? 0 : "-=200",
        complete: function() {
            // Set transition to inactive
            megaMenu.activeTransition = false;

            // Remove active from trigger
            subMenuData.trigger.removeAttribute("data-active");
            // Remove active from list
            subMenuData.element.removeAttribute("data-active");

             // Set list to open
             trayDetails.columns.navColumn.removeAttribute("data-open");

            // If there is a callback
            if(callback) {
                // Fire Callback
                callback();
            }
        }
    });

    // If extended
    if(subMenuData.extended === true) {

         // Reset translate on button
         timeline.add({
            targets: trayDetails.columns.navColumn.querySelector(".mega-menu-tray__nav"),
            paddingLeft: "40px",
            duration: (megaMenu.hardReset === true) ? 0 : 100,
            easing: "linear",
            offset: (megaMenu.hardReset === true) ? 0 : "-=400"
        });

        // Reset the translate position of the container
        timeline.add({
            targets: trayDetails.trayElementContainer,
            translateX: "0%",
            duration: (megaMenu.hardReset === true) ? 0 : 400,
            easing: "linear",
            offset: (megaMenu.hardReset === true) ? 0 : "-=400"
        });


        // Animate In Promo
        timeline.add({
            targets: trayDetails.columns.infoColumn,
            opacity: 1,
            duration: (megaMenu.hardReset === true) ? 0 : 200,
            easing: "linear",
            complete: function() {
                // If hard reset is set
                if(megaMenu.hardReset === true) {
                    // Reset the hard reset value
                    megaMenu.hardReset = false;
                }
            }
        });

    }

    // Add Timeline to instance
    megaMenu.anime = timeline;

    // Play timeline
    megaMenu.anime.play();

};