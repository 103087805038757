//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';

//==================================================================================================
//  Private Functions
//==================================================================================================
/**
 * Function to animate in the opacity
 * @param {Array} args - The binded instance
 * @paran {HTMLElement} args[0] - The element to be checked / changed
 * @ignore
 */

// Set placeholder for rticle banner scroll
let articleBannerPageScroll = window.pageYOffset;

function changeOpacityScroll(args) {
    // Define max allowed opacity
    const maxOpacity = "0.7";

    // Define the passed in element
    const element = args[0];

    // Check if max opacity is reached
    if(element.style.opacity !== maxOpacity || (window.pageYOffset < articleBannerPageScroll)) {

        // Get the element height
        const elementHeight = element.offsetHeight;

        // Get the window scroll position
        const scrollTop = window.pageYOffset;

        // Define the range
        const range = 200;

        // Calculate offset
        const offset = elementHeight / 2;

        // Calculate the percentage to be set as the opacity
        const opacityValue = (scrollTop - offset + range) / range;

        // If the opacity value is more than 0.7
        if(opacityValue > maxOpacity) {
        // Cap the opacity value at 0.7
            element.style.opacity = maxOpacity;
        }
        // If the opacity value is less than 0
        else if(opacityValue < '0') {
            // Cap the minimum value at 0
            element.style.opacity = 0;
        }
        else {
            // Update the opacity
            element.style.opacity = opacityValue;
        }

        // Update scroll position
        articleBannerPageScroll = scrollTop;
    }

}

//==================================================================================================
//
//	Banner Opacity
//  Updates opacity in banner on page scroll
//
//==================================================================================================
new DOMReady(function initBannerOpacity() {
    // Select all opacity banners
    const bannerOpacityElements = toArray(document.querySelectorAll("[ data-banner-opacity]"));

    // If elements exist
    if(bannerOpacityElements.length > 0) {
        // Loop though opacity layers
        bannerOpacityElements.forEach(function(currentItem) {

            // Create scroll event
            const bannerScroll = changeOpacityScroll.bind(this, [currentItem]);

            // Add Scroll Event
            window.addEventListener("scroll", bannerScroll);
        });
    }
});