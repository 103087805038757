

//==================================================================================================
//
//	Show or hide an element using the "aria-hidden" attribute
//
//==================================================================================================


//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * Show/hide functionality.
 * This can be inherited from (or instantiated) as required.
 * @constructor
 * @param {HTMLElement} trigger - Element which will toggle our view
 * @param {Function} callback - Callback to be fired when our view state changes. <br>
 *                              <b>Changelog</b>
 *                              1Mar2018 - The callback is now called at the *very end* of setViewState(); *after* the 'hidden' property is updated.
 * @property {HTMLElement} trigger - Local reference to our trigger argument.
 * @property {HTMLElement} view - View element for our CTA, this is derived from the "aria-controls" element attribute.
 * @property {Function} callback - Local reference to our callback argument.
 * @property {String} activeClass - Class to be used to toggle the state of our view elements, defaults to ".hidden".
 * @property {Boolean} hidden - Reflects the current state of the view. When updated this will automatically show/hide as appropriate.
 *
 */
export const ToggleView = function(trigger, callback) {
    const toggle = this;
    let hidden = true;
    // let activeClass = "active";

    toggle.trigger = trigger;
    toggle.view = document.getElementById(trigger.getAttribute("aria-controls"));
    toggle.callback = callback;

    // Object.defineProperty(toggle, "activeClass", {
    //     get: function getActiveClass() {
    //         return activeClass;
    //     },
    //     set: function setActiveClass(value) {
    //         activeClass = value;
    //         return activeClass;
    //     }
    // });

    Object.defineProperty(toggle, "hidden", {
        get: function getViewState() {
            return hidden;
        },
        set: function setViewState(value) {
            const state = value ? false : true;

            //  If our view is open then close it
            if (value === true || value === false && value !== hidden) {

                // console.log("toggling");

                toggle.trigger.setAttribute("aria-expanded", state);
                toggle.view.setAttribute("aria-hidden", value);

                // if (value === false) {
                //     toggle.view.addClass(activeClass);
                // }
                // else {
                //     toggle.view.removeClass(activeClass);
                // }


                //  Update our local state
                hidden = value;

                //  Fire our callback if it exists
                if (toggle.callback) {
                    toggle.callback(toggle);
                }


            }
        }
    });
};


//==================================================================================================
//	Alternate state
//==================================================================================================
/**
 * Toggles the state of our view between open & closed
 */
ToggleView.prototype.toggle = function() {
    const toggle = this;

    if (toggle.hidden === false) {
        toggle.hidden = true;
    }
    else {
        toggle.hidden = false;
    }
};
