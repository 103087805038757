//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { Resize } from 'objects/shared/Resize';
import { scrollToElement } from 'implementation/scrollToElement/scrollToElement';

//==================================================================================================
//  Private Functions
//==================================================================================================
/**
 * Function to animate in the opacity
 * @param {Array} args - The binded instance
 * @paran {HTMLElement} args[0] - The element to be checked / changed
 * @ignore
 */
function showBackToTop(args) {
    // Get Back To Top from the args
    const backToTop = args[0];
    const backToTopButton =backToTop.querySelector("[data-scroll-to]");

    // Get current y offset
    const windowScroll = window.pageYOffset;

    // Get window height
    const body = document.body, html = document.documentElement;

    const windowHeight = Math.max(body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight);

    // Working out 40% of window height
    const scrollMax = windowHeight * 0.4;

    //Is BackToTop visible right now?
    const canSeeBackToTop = backToTop.hasAttribute("data-back-to-top-show");

    //If we are 40% scrolled down the page and BackToTop is not visible
    if(window.pageYOffset > scrollMax && !canSeeBackToTop) {
        //Show BackToTop
        backToTop.setAttribute("data-back-to-top-show","");

        //Accessibility: TabIndex On / Selectable
        if(backToTopButton){
            backToTopButton.setAttribute("tabindex","0");
        }

    //Else If we are not 40% scrolled down the page and BackToTop is visible
    } else if (window.pageYOffset < scrollMax && canSeeBackToTop) {
        //Hide BackToTop
        backToTop.removeAttribute("data-back-to-top-show");

        //Accessibility: TabIndex Off / Non selectable
        if(backToTopButton){
            backToTopButton.setAttribute("tabindex","-1");
        }
    }
}

//==================================================================================================
//
//	Back To Top Opacity
//  Shows/Hides Back To Top Button on scroll
//
//==================================================================================================
new DOMReady(function initBackToTopOpacity() {
    // Select all back to top items
    const backToTopItems = toArray(document.querySelectorAll("[data-back-to-top]"));

    // If elements exist
    if(backToTopItems.length > 0) {
        // Loop though back to top items
        backToTopItems.forEach(function(backToTopItem) {

            // Bind event to the item
            const showBackToTopBound = showBackToTop.bind(this, [backToTopItem]);

            // Add Scroll Event
            window.addEventListener("scroll", showBackToTopBound);

            // Add Resize Event
            new Resize(showBackToTopBound);

            // Call Scroll Bound
            showBackToTopBound();
        });
    }
});