//==================================================================================================
//
//	Social Share
//
//==================================================================================================

//==================================================================================================
//  Share URLs
//==================================================================================================
/**
 * Share API URLs
 */
const shareUrls = {
	twitter: 'https://twitter.com/intent/tweet?&text={text}&hashtags={hashtags}&related={related}&url={url}',
	facebook: 'https://www.facebook.com/sharer/sharer.php?u={url}',
	pinterest: 'http://pinterest.com/pin/create/button/?url={url}&description={text}&media={image}',
	google: 'https://plus.google.com/share?url={url}',
	linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url={url}&summary={text}&source={source}',
	email: 'mailto:{mail}?subject={subject}&body={body}'
};


//==================================================================================================
//  Private Methods
//==================================================================================================
/**
 * Get Data Attributes
 */
function getDataAttributes(node) {

	const datAttributeList = {};
	const dataAttrRegEx = /^data\-(.+)$/;

	for (let i = 0; i < node.attributes.length; i++){
		const nodeAttribute = node.attributes[i].nodeName;
		if(dataAttrRegEx.test(nodeAttribute)) {
			datAttributeList[node.attributes[i].nodeName.match(dataAttrRegEx)[1]] = node.attributes[i].nodeValue;
		}
	}

	return datAttributeList;
}

//==================================================================================================
//	Constructor
//==================================================================================================
export const SocialShare = function(button) {
    const socialShare = this;

    socialShare.button = button;
    socialShare.dataAttributes = getDataAttributes(button);

    // if url attribute is not defined use page HREF
	if(!socialShare.dataAttributes.url) {
		socialShare.dataAttributes.url = encodeURIComponent(window.location.href);
	}

	// handle click
	socialShare.button.addEventListener('click', (e) => {
		e.preventDefault();
		socialShare.share();
	}, false);
};


//==================================================================================================
//	Share
//==================================================================================================
/**
 * Opens a share window
 * @return {void}
 */
SocialShare.prototype.share = function () {
    const SocialShare = this;

	if(SocialShare.dataAttributes.social === 'email'){
		window.location = this.getShareUrl();
	} else {
		window.open(SocialShare.getShareUrl(), 'shareDialog', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=660,height=480');
	}
};


//==================================================================================================
//	Get Share Url
//==================================================================================================
/**
 * Creates share URL for a specific social channel
 * @return {string} - share url
 */
SocialShare.prototype.getShareUrl = function () {
	const SocialShare = this;

	SocialShare.shareUrl = shareUrls[SocialShare.dataAttributes.social];

	// replace all URL attributes
	for(const attribute in this.dataAttributes){
		if(SocialShare.dataAttributes.hasOwnProperty(attribute)){
			SocialShare.shareUrl = SocialShare.shareUrl.replace('{' + attribute + '}', SocialShare.dataAttributes[attribute]);
		}
	}

	// replace all placeholders e.g. {text} with empty string
	SocialShare.shareUrl = SocialShare.shareUrl.replace(/({.*?})/g, '');

	return this.shareUrl;
};
