import { DOMReady } from 'objects/shared/DOMReady';

// Observes a given field in a form with conditional-action

new DOMReady(() => {

	const conditional_action_form = document.querySelector('form[data-conditional-action]');

	if(conditional_action_form){
		const original_action = conditional_action_form.getAttribute('action');
		const conditional_action = conditional_action_form.getAttribute('data-conditional-action');
		const conditional_field_selector = conditional_action_form.getAttribute('data-conditional-field')
		const conditional_value = conditional_action_form.getAttribute('data-conditional-value').toLowerCase();
		const conditional_field = conditional_action_form.querySelector('input[name="' + conditional_field_selector + '"]');

		conditional_field.addEventListener('change', function checkField(event){

			let field_value = event.target.value.toLowerCase();

			if(field_value.indexOf(conditional_value) != -1 && conditional_action_form.getAttribute('data-action-changed') != 'true'){
				conditional_action_form.setAttribute('action', conditional_action);
				conditional_action_form.setAttribute('data-action-changed', 'true');
			}
			// if the conditional value is not detected but the target was already changed, change it back.
			else if(field_value.indexOf(conditional_value) == -1 && conditional_action_form.getAttribute('data-action-changed') == 'true') {
				conditional_action_form.setAttribute('action', original_action);
				conditional_action_form.setAttribute('data-action-changed', 'false');
			}
		})
	}

});
