//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';

//==================================================================================================
//	Private function
//==================================================================================================
function calculateScrollPercentage() {
    var h = document.documentElement,
        b = document.body,
        st = 'scrollTop',
        sh = 'scrollHeight';

    return (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
}

function isElementVisible(element) {
    var h = document.documentElement,
        b = document.body,
        st = 'scrollTop';

    return (h[st] || b[st]) <= element.offsetTop + element.offsetHeight;
}

//==================================================================================================
//
//	Sticky Header
//
//==================================================================================================
new DOMReady(function initStickyHeader() {
    var stickyHeader = document.getElementsByClassName('sticky-header__container');

    if (stickyHeader.length > 0) {
        window.addEventListener('scroll', function (e) {
            var mainNavbar = document.getElementsByClassName('main-navbar')[0];
            var stickyHeaderContainer = document.getElementsByClassName('sticky-header__container')[0];

            if (!isElementVisible(mainNavbar)) {
                stickyHeaderContainer.addClass('sticky-header__container__displayed');
            } else {
                stickyHeaderContainer.removeClass('sticky-header__container__displayed');
            }

            var progress = calculateScrollPercentage();

            var stickyHeader = document.getElementsByClassName('sticky-header__progress-bar')[0];
            stickyHeader.style.width = progress + '%';
        });

        var shareButton = document.getElementsByClassName('sticky-header__share')[0];

        shareButton.addEventListener('click', (e) => {
            if (e.currentTarget.hasClass('sticky-header__share__open')) {
                e.currentTarget.lastElementChild.innerHTML = 'SHARE';
            } else {
                e.currentTarget.lastElementChild.innerHTML = 'SHARE ON:';
            }

            e.currentTarget.toggleClass('sticky-header__share__open');

            var logo = document.getElementsByClassName('sticky-header__logo')[0];
            var title = document.getElementsByClassName('sticky-header__title')[0];
            logo.toggleClass('sticky-header__logo__hidden');
            title.toggleClass('sticky-header__title__hidden');

            var socialShareElements = toArray(document.querySelectorAll("[data-link='share']"));

            if (socialShareElements.length > 0) {
                socialShareElements.forEach(function (currentElement) {
                    currentElement.toggleClass('sticky-header__share-buttons__displayed');
                });
            }
        }, false);
    }    
});