//==================================================================================================
//
//	Normalise syntax for HTMLElement.matches for IE9+
//
//==================================================================================================
/**
 * Normalise syntax for HTMLElement.matches for IE9+
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
 * @function external:HTMLElement#matches
 */
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}