//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";
import { toArray } from "helpers/shared/toArray";
//==================================================================================================
//
//	Login Overlay
//
//==================================================================================================

//==================================================================================================
//	Private Functions
//==================================================================================================
/**
 * Used to open the login overlay
 * @param {Object} instance - The current login overlay instance
 * @ignore
 * @example
 * // Open the login overlay
 * openLoginOverlay(loginOverlay);
 */
function openLoginOverlay(instance) {
    // Cache instance
    const loginOverlay = instance;

    // Set active to true
    loginOverlay.activeTransition = true;

    // animate in login overlay
    anime({
        begin: function() {
            // Disable the login overlay
            loginOverlay.element.setAttribute("aria-hidden", "false");
            // Set element to visible
            loginOverlay.element.style.visibility = "visible";
            // Open the login overlay
            loginOverlay.element.setAttribute("aria-expanded", "true");
        },
        targets: loginOverlay.element,
        opacity: 1,
        duration: 100,
        delay: 100,
        complete: function() {
            // Set active animation to false
            loginOverlay.activeTransition = false;
            // Update the status of the overlay to be active
            loginOverlay.active = true;
        }
    });
}

/**
 * Used to close the login overlay
 * @param {Object} instance - The current login overlay instance
 * @ignore
 * @example
 * // Close the login overlay
 * closeLoginOverlay(loginOverlay);
 */
function closeLoginOverlay(instance) {
    // Cache instance
    const loginOverlay = instance;

    // Set active to true
    loginOverlay.activeTransition = true;

    // Close the login overlay
    loginOverlay.element.setAttribute("aria-expanded", "false");

    // Animate the element out
    anime({
        targets: loginOverlay.element,
        opacity: 0,
        duration: 100,
        delay: 600,
        complete: function() {
            // Disable the login overlay
            loginOverlay.element.setAttribute("aria-hidden", "true");

            // Set the element to invisible
            loginOverlay.element.style.visibility = "hidden";

            // Set active to false
            loginOverlay.activeTransition = false;

            // Restore the users focus
            if(loginOverlay.previousActiveElement) {
                loginOverlay.previousActiveElement.focus();
            }

            // Update the status of the overlay to be inactive
            loginOverlay.active = false;
        }
    });
}


//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * This is used to control the login overlay part of the navigation
 * @constructor
 * @param {HTMLElement} element - The login overlay element.
 * @param {Array} triggers - Array of html elements used to open the overlay.
 * @param {HTMLElement} closeBtn - The overlay close button element.
 *
 * @property {HTMLElement} element - The login overlay element
 * @property {Array} triggers - Array of html elements used to open the overlay.
 * @property {HTMLElement} closeBtn - The overlay close button element.
 * @property {Boolean} activeTransition - Tracks if the overlay is currently animating.
 * @property {HTMLElement} previousActiveElement - The previous active element to be restored when overlay is closed.
 *
 * @example
 * // Check for login the overlay element
 * const loginOverlayElement = document.querySelector("[data-login-overlay]");
 *
 * // If element is present
 * if(loginOverlayElement) {
 *
 *      // Lookup triggers
 *      const triggerElements = toArray(document.querySelectorAll("[data-login-trigger]"));
 *
 *      // Look up close button
 *      const loginOverlayCloseBtn = loginOverlayElement.querySelector("[data-login-overlay-close]");
 *
 *      // If elements exist
 *      if(triggerElements.length > 0) {
 *
 *          // Create new login overlay
 *          const loginOverlay = new LoginOverlay(loginOverlayElement, triggerElements, loginOverlayCloseBtn);
 *      }
 * }
 */
export const LoginOverlay = function(element, triggers, closeBtn) {
    // Cache instance
    const loginOverlay = this;

    // Define properties
    loginOverlay.element = element;
    loginOverlay.menuItems = toArray(document.querySelectorAll("[data-primary-nav-item]"));
    loginOverlay.triggers = triggers;
    loginOverlay.closeOnHover = [];
    loginOverlay.closeBtn = closeBtn;
    loginOverlay.activeTransition = false;

    // Initiate login Overlay
    loginOverlay.init();
};

//==================================================================================================
//	Init
//==================================================================================================
/**
 * Initiates the Login Overlay
 * @memberOf LoginOverlay
 */
LoginOverlay.prototype.init = function() {
    // cache instance
    const loginOverlay = this;

    // Attach the events
    loginOverlay.attachEvents();
};


//==================================================================================================
//	Open
//==================================================================================================
/**
 * Opens the Login Overlay
 * @memberOf LoginOverlay
 */
LoginOverlay.prototype.open = function() {
     // Cache instance
     const loginOverlay = this;

     // check if an animation is active
     if(loginOverlay.activeTransition !== true) {

        // Store the current active item
        loginOverlay.previousActiveElement = document.activeElement;

         // Open the login overlay
         openLoginOverlay(loginOverlay);
     }
};

//==================================================================================================
//	Close
//==================================================================================================
/**
 * Closes the Login Overlay
 * @memberOf LoginOverlay
 */
LoginOverlay.prototype.close = function() {
    // Cache instance
    const loginOverlay = this;

    // check if an animation is active
    if(loginOverlay.activeTransition !== true && loginOverlay.active === true) {
        // Close the login overlay
        closeLoginOverlay(loginOverlay);
    }
};

//==================================================================================================
//	Attach Events
//==================================================================================================
/**
 * Attaches events to the Login Overlay
 * @memberOf LoginOverlay
 */
LoginOverlay.prototype.attachEvents = function() {
    // Cache instance
    const loginOverlay = this;

    // Loop through the triggers
    loginOverlay.triggers.forEach(function(currentTrigger) {
        // Add click event to the trigger
        currentTrigger.addEventListener("click", function(event) {
            // Prevent default
            event.preventDefault();
            // If the login overlay is not active
            if(loginOverlay.active !== true) {
                // Open the overlay
                loginOverlay.open();
            }
            else {
                // Close the overlay
                loginOverlay.close();
            }

        });

        //// Add mouse enter event
        //currentTrigger.addEventListener("mouseenter", () => {
        //    // Clear the timeout
        //    clearTimeout(loginOverlay.openTimeout);
        //    clearTimeout(loginOverlay.mainNavTimeout);
        //    // Set timeout to allow the user to change their mind
        //    loginOverlay.openTimeout = setTimeout(() => {
        //        // Open the overlay
        //        loginOverlay.open();
        //    }, 50);
        //});
    });

    // Add mouse enter event to menu items
    loginOverlay.menuItems.forEach((currentMenuItem) => {

        // If the menu item is the login trigger
        if(currentMenuItem.getAttribute("data-login-trigger") !== null) {
            // Return false
            return false;
        }

        // Check for touch device
        const touch = matchMedia('(hover: none)').matches;

        if (touch) {
            // Set mouse enter event
            currentMenuItem.addEventListener("mouseenter", () => {
                // Set timeout to allow the user to change their mind
                loginOverlay.mainNavTimeout = setTimeout(() => {
                    // Close the overlay
                    loginOverlay.close();
                }, 50);
            });
        }
    });

    // Check if there is a close button
    if(loginOverlay.closeBtn) {
        // Add close event
        loginOverlay.closeBtn.addEventListener("click", function(event) {
            // Prevent Default
            event.preventDefault();
            // Close the overlay
            loginOverlay.close();
        });
    }

    // Mouse Leave To Close Overlay
    loginOverlay.element.addEventListener("mouseleave", () => {
        clearTimeout(loginOverlay.openTimeout);
        // Close the overlay
        loginOverlay.close();
    });

    // Loop through close on hover
    loginOverlay.closeOnHover.forEach((currentItem) => {
        currentItem.addEventListener("mouseenter", () => {
            loginOverlay.close();
        });
    });
};
