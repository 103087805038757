import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";
import { keyAccessibility } from "helpers/keyAccessibility";

new DOMReady(() => {
    //Epi server team advise they can't change the markup that is generated
    //before/after elements on the label are used to create the styled radio button
    //the markup wraps the label around the radio button, so CSS can't style the label based on the radio check state
    //therefore script is needed to add a class to the label when the checked state changes
    const containerSelector = ".checkbox-container, .FormChoice";
    const containers = toArray(document.querySelectorAll(containerSelector));

    const clickHandler = (container) => {
        let inputs = container.querySelectorAll("input[type='radio'], input[type='checkbox']")

        inputs = toArray(inputs);

        inputs.forEach((input) => {
            if (input.checked) {
                input.parentNode.addClass("input-checked");
            }
            else {
                input.parentNode.removeClass("input-checked");
            }
        });
    };

    const forms = toArray(document.querySelectorAll("form"));

    forms.forEach((form)=>{
        form.addEventListener("reset",()=>{
            setTimeout(()=>clickHandler(form));
        });
    });

    containers.forEach((container) => {

        const labels = toArray(container.querySelectorAll("label"));

        //Need to add keyboard a11y for the labels, because the actual radio buttons are set to display:none so can't receive focus
        labels.forEach((label)=>{
            label.setAttribute("role","button");
            label.setAttribute("tabindex","0");

            const keyHandler = (event)=>{
                if(keyAccessibility.isSpaceOrEnter(event)){
                    event.preventDefault();
                    label.click();
                  }
            };

            label.addEventListener("keydown", keyHandler);
        });

        //Need a delegated event as checked state of radio will change when sibling is clicked
        container.addEventListener("click", () => clickHandler(container), true);
        clickHandler(container);
    });
});