//==================================================================================================
//	Dependencies
//==================================================================================================
import { keyAccessibility } from "helpers/keyAccessibility"
//==================================================================================================
//
//	Navigation Search
//
//==================================================================================================

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * This is used to provide search functionality to the header
 * @constructor
 * @param {HTMLElement} menu - The menu element.
 * @param {HTMLElement} searchContainer - The search container element.
 * @param {HTMLElement} searchTrigger - The Trigger element.
 *
 * @property {HTMLElement} menu - The menu element.
 * @property {HTMLElement} searchContainer - The search container element.
 * @property {HTMLElement} trigger - The Trigger element.
 * @property {HTMLElement} input - The Input element.
 * @property {Boolean} status - The status of the menu (open / close).
 * @property {Get/Set} active - Changes the status.
 *
 * @example
 * // Select the navigation from the DOM
 * const navigationContainer = document.querySelector(".main-navbar");
 *
 * // If navigation container is present
 * if(navigationContainer) {
 *      // Look up nav search container
 *      const searchContainer = navigationContainer.querySelector("[data-menu-search]");
 *
 *      // Look up search trigger
 *      const searchTrigger = navigationContainer.querySelector("[data-menu-search-trigger]");
 *
 *      // If nav search container is present
 *      if(searchContainer) {
 *          // Create a new navigation search instance
 *          const menuSearch = new MenuSearch(navigationContainer, searchContainer, searchTrigger);
 *      }
 * }
 */
export const MenuSearch = function(menu, searchContainer, searchTrigger) {
    // Cache instance
    const menuSearch = this;

    // Set properties
    menuSearch.menu = menu;
    menuSearch.searchContainer = searchContainer;
    menuSearch.trigger = searchTrigger;
    menuSearch.input = searchContainer.querySelector("[data-menu-search-box]");
    menuSearch.status = false;

    // Define open property
    Object.defineProperty(this, "active", {
        get: function() {
            return this.status;
        },
        set: function(newStatus) {
            // Set the new status
            this.status = newStatus;
            // Update the UI
            this.updateUI();
        }
    } );

    // Call init
    menuSearch.init();

};

//==================================================================================================
//	Init
//==================================================================================================
/**
 * Initiates the navigation search
 */
MenuSearch.prototype.init = function() {
    // Cache instance
    const menuSearch = this;

    // Attach events
    menuSearch.attachEvents();

};

//==================================================================================================
//	Attach Events
//==================================================================================================
/**
 * Adds events to the search element
 */
MenuSearch.prototype.attachEvents = function() {
    // Cache instance
    const menuSearch = this;

    // Attach the open / close event
    // Click
    menuSearch.trigger.addEventListener("click", function handleClick() {
        // Toggle the search box
        menuSearch.toggleSearchBox();
    });

    // Keyboard
    menuSearch.trigger.addEventListener("keydown", function handleKeyPress(event) {
        // Check if enter / space was pressed
        if(keyAccessibility.isSpaceOrEnter(event) && event.target !== menuSearch.input) {
            // Prevent Default (prevent space pulling user down page)
            event.preventDefault();
            // toggle the search box
            menuSearch.toggleSearchBox();
        }
    });

};

//==================================================================================================
//	Toggle Search Box
//==================================================================================================
/**
 * Opens / Closes the Search box
 */
MenuSearch.prototype.toggleSearchBox = function() {
    // Cache the instance
    const menuSearch = this;

    // Check if active is not set
    if(menuSearch.active === false) {
        // Set active to true
        menuSearch.active = true;
    }
    else {
        // Set active to false
        menuSearch.active = false;
    }

};

//==================================================================================================
//	Update UI
//==================================================================================================
/**
 * Updates the UI
 */
MenuSearch.prototype.updateUI = function() {
    // Cache instance
    const menuSearch = this;

    // Show the navigation
    if(menuSearch.active === true) {
        // Apply aria propery to the search box
        menuSearch.input.setAttribute("aria-hidden", "false");
        // Update the menu to indicate search is open
        menuSearch.menu.setAttribute("data-menu-search-open", "");
        // Make input tabbable
        menuSearch.input.setAttribute("tabindex", "0");
        // Toggle focus on the input
        menuSearch.input.focus();
    }
    // Hide the navigation
    else {
        // Apply aria propery to the search box
        menuSearch.input.setAttribute("aria-hidden", "true");
        // Make input not tabbable
        menuSearch.input.setAttribute("tabindex", "-1");
        // Update the menu to indicate search is closed
        menuSearch.menu.removeAttribute("data-menu-search-open");
    }
};