//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { LoginOverlay } from "./LoginOverlay";

//==================================================================================================
//
//	Navigation Tracker
//
//==================================================================================================

//==================================================================================================
//	Private Functions
//==================================================================================================

/**
 * Calculates and elements size and offset
 * @param {HTMLElement} element - The element to be checked for an offset
 * @ignore
 * @returns {Object} data - The size data containing the offset
 * @example
 * // Store current selected
 * const currentSelected = event.target;
 *
 * // Calculate required values for the element
 * const elementSize = getSize(currentSelected);
 */
function getSize(element) {
    // check if element exists
    if(element) {

        // Create data object
        const data = {
            offsetLeft: (element.offsetLeft) + (element.clientWidth * 0.5),
            width: element.clientWidth
        };

        // Return the data
        return data;

    }
    else {
        return false;
    }
}

/**
 * Handles the target position event
 * @param {Array} args - The bound arguments
 * @ignore
 * @example
 * // Loop through menu items
 * navigationTracker.menuItems.forEach(function(currentItem) {
 *      // Add Click Mouse Over Event
 *      currentItem.addEventListener("mouseover", function handleHover(event) {
 *          // Create th function to fire
 *          const hoverFunction = handleTargetPositionEvent.bind(navigationTracker, [event]);
 *          // Fire the function
 *          hoverFunction();
 *      });
 * });
 */
function handleTargetPositionEvent(args) {
    // Cache instance
    const navigationTracker = this;

    // Set the tracker to visible
    navigationTracker.tracker.style.opacity = 1;

    // Retrieve the event
    const event = args[0];

    // Store current selected
    const currentSelected = event.target.parentElement;

    // Only track, if a menu item is selected
    if(navigationTracker.menuItems.indexOf(currentSelected) === "-1") {
        // Cancel is not a menu item
        // Stops trigger being fired when a non nav item is pressed
        return false;
    }

    // Calculate required values for the element
    const elementSize = getSize(currentSelected);

    // Update the position of the element
    navigationTracker.updatePosition(elementSize.width, elementSize.offsetLeft);

}


//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * This is used to provide navigation item tracking within the navigation to show the current mouseover target
 * @constructor
 * @param {HTMLElement} menu - The menu element.
 * @param {HTMLElement} tracker - The tracker element.
 *
 * @property {HTMLElement} element - The menu element.
 * @property {HTMLElement} menu - The menu element.
 * @property {HTMLElement} currentActive - The current active item.
 * @property {Array} menuItems - Array of list items
 * @property {HTMLElement} tracker - The tracker element.
 *
 * @example
 * // Select the navigation from the DOM
 * const navigation = document.querySelector("[data-mega-menu]");
 *
 * // If navigation exists
 * if(navigation) {
 *      // Select the tracker from the dom
 *      const tracker = navigation.querySelector("[data-mega-menu-tracker]");
 *
 *      // Check if tracker exists
 *      if(tracker === null) {
 *          console.warn("Tracker element not defined for navigation");
 *          return false;
 *      }
 *
 *      // Create a new navigation tracker
 *      const navigationTracker = new NavigationTracker(navigation, tracker);
 *
 * }
 *
 */
export const NavigationTracker = function(menu, tracker) {
    // Cache instance
    const navigationTracker = this;

    // Define properties
    navigationTracker.element = menu;
    navigationTracker.menu = menu.querySelector(".mega-menu__list");
    navigationTracker.currentActive = menu.querySelector(".mega-menu__item--active a");
    navigationTracker.menuItems =  toArray(navigationTracker.menu.querySelectorAll("[data-primary-nav-item]"));
    navigationTracker.hoverResetItems = [document.querySelector("[data-menu-search]")];
    navigationTracker.tracker = tracker;

    // Initiate
    navigationTracker.init();

};

//==================================================================================================
//	Init
//==================================================================================================
/**
 * Initiates the navigation tracker
 * @memberOf NavigationTracker
 */
NavigationTracker.prototype.init = function() {
    // Cache instance
    const navigationTracker =this;

    // Attach the events
    navigationTracker.attachEvents();

    // Create new
    // If a current active item is set
    if(navigationTracker.currentActive !== undefined && navigationTracker.currentActive !== null) {
        window.setTimeout(function() {
            navigationTracker.setStartItem();
        }, 250);
    }
    else {
        // set a default start position for the nav tracker bar
        window.setTimeout(() => {
            navigationTracker.setStartItem(true);
        }, 250);
    }

};

//==================================================================================================
//	Set a starting element
//==================================================================================================
/**
 * Displays the current active nav item on load
 * @memberOf NavigationTracker
 */
NavigationTracker.prototype.setStartItem = function(setDefault) {
    // Cache instance
    const navigationTracker =this;

    // Placeholder elements
    let currentActiveItem;

    // If not Setting default
    if(setDefault) {
        // Set  default position for the tracker
        currentActiveItem = navigationTracker.menuItems[0];
    }
    else {
        // get the current active item
        currentActiveItem = navigationTracker.currentActive;
    }

    // Get the size of the element
    const elementSize = getSize(currentActiveItem);


    // Update the position
    navigationTracker.updatePosition(elementSize.width, elementSize.offsetLeft, setDefault);
};

//==================================================================================================
//	Attach Events
//==================================================================================================
/**
 * Adds events to the menu items
 * @memberOf NavigationTracker
 */
NavigationTracker.prototype.attachEvents = function() {
    // Cache instance
    const navigationTracker = this;

    // Loop through menu items
    navigationTracker.menuItems.forEach(function(currentItem) {
        // Add Click Mouse Over Event
        currentItem.addEventListener("mouseover", function handleHover(event) {

            // Check if this is the login overlay
            if(currentItem.hasAttribute("data-login-trigger")) {
                navigationTracker.staySet = true;
            }
            else {
                navigationTracker.staySet = false;
            }

            // Create th function to fire
            const hoverFunction = handleTargetPositionEvent.bind(navigationTracker, [event]);
            // Fire the function
            hoverFunction();
            // Set the current Hovered Item
            navigationTracker.currentHoveredItem = currentItem;
        });

        // Add focus event
        currentItem.addEventListener("focusin", function handleFocus(event) {
            // Create th function to fire
            const hoverFunction = handleTargetPositionEvent.bind(navigationTracker, [event]);
            // Fire the function
            hoverFunction();
            // Set the current Hovered Item
            navigationTracker.currentHoveredItem = currentItem;
        });

    });

    // Add events for the list to show / hide the tracker element
    navigationTracker.menu.addEventListener("mouseover", () => {
        // Show the tracker element
        navigationTracker.tracker.style.opacity = 1;
    });

    navigationTracker.menu.addEventListener("mouseleave", () => {
        // Handle Mouse Leave
        navigationTracker.mouseLeave({staySet: true});
    });

    const loginOverlayElement = document.querySelector("[data-login-overlay]");

    if (loginOverlayElement) {
        // CLose when leaving login overlay
        loginOverlayElement.addEventListener("mouseleave", () => {
            // Handle Mouse Leave
            navigationTracker.mouseLeave();
        });
    }

    // Close when hovering on
    navigationTracker.hoverResetItems.forEach((currentItem) => {
        currentItem.addEventListener("mouseenter", () => {
            // Handle Mouse Leave
            navigationTracker.mouseLeave();
        });
    });
};

//==================================================================================================
//	Attach Events
//==================================================================================================
/**
 * Updates the tracker position
 * @memberOf NavigationTracker
 * @param {Number} trackerWidth - The new width of the tracker element.
 * @param {Number} newPosition - The position for the tracker to travel to.
 * @memberOf NavigationTracker
 * // Handle Mouse Leave
 * navigationTracker.mouseLeave();
 */
NavigationTracker.prototype.updatePosition = function(trackerWidth, newPosition, hideAnimation) {
    // Cache instance
    const navigationTracker = this;

    // Calculate the new offset (minus the parent list offset)
    const newOffset = newPosition - navigationTracker.menu.offsetLeft;

     // Animate the tracker to the correct item
     navigationTracker.tracker.style.width =  trackerWidth + "px";
     navigationTracker.tracker.style.transform = `translatex(${newOffset}px) translateX(-50%)`;

     // Set the opacity to 1
     if(!hideAnimation) {
        setTimeout(() => {
            navigationTracker.tracker.style.opacity = 1;
        }, 200);
    }

};

/**
 * Used to handle mouse leave
 * @param {Object} options - The Options available to the method.
 * @param {Boolean} options.staySet - Wether to check stay set value;
 */
NavigationTracker.prototype.mouseLeave = function(options) {
    // Cache Instance
    const navigationTracker = this;

    // Check if there is an active start item
    if(navigationTracker.currentActive !== undefined && navigationTracker.currentActive !== null) {

        // Check If the item currently hovered is the active item
        if(navigationTracker.currentHoveredItem === navigationTracker.currentActive) {
            // Cancel execution and keep the active item present
            return false;
        }

        // Check if there is options
        if(options) {
            if(options.staySet) {
                if(navigationTracker.staySet) {
                    return false;
                }
            }
        }

        // Re-set the active item
        navigationTracker.tracker.style.opacity = 0;

        // Set timeout to allow element to be hidden before changing
        setTimeout(() => {
            // Get the size of the element
            const elementSize = getSize(navigationTracker.currentActive);
            // Update the position
            navigationTracker.updatePosition(elementSize.width, elementSize.offsetLeft);
        }, 300);


    }
    else {
        // Hide the nav tracker
        navigationTracker.tracker.style.opacity = 0;
    }
};