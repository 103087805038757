//==================================================================================================
//
//	Add class to HTMLElement
//
//==================================================================================================

if (HTMLElement.addClass === undefined) {

    /**
     * Add a class to our HTMLElement
     * @function external:HTMLElement#addClass
     * @param {String} className - Class to be added to our element
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".main");
     * element.addClass("active");
     */
    HTMLElement.prototype.addClass = function(className) {
        const element = this;

        if (element.classList.contains(className) === false) {
            element.classList.add(className);
        }

        return element;
    };

    /**
     * Add a class to our SVGElement
     * @function external:SVGElement#addClass
     * @see external:HTMLElement#addClass
     */
    SVGElement.prototype.addClass = HTMLElement.prototype.addClass;

}