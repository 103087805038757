//==================================================================================================
//
//	Find all parent elements which match a selector
//
//==================================================================================================

if (HTMLElement.parents === undefined) {

    /**
     * Find ancestor elements of our HTMLElement
     * @function external:HTMLElement#parents
     * @param {String} [selector] - Selector to used for limiting results
     * @returns {Array}
     * @example
     * let element = document.querySelector(".button");
     * let parents = element.parents(".container");
     */
    HTMLElement.prototype.parents = function(selector) {
        const element = this;
        const result = [];
        let parent = element;

        while(parent && parent.matches) {
            if (selector) {
                if (parent.matches(selector)) {
                    result.push(parent);
                }
            }
            else {
                result.push(parent);
            }
            parent = parent.parentNode;
        }

        return result;
    };

    /**
     * @function external:SVGElement#parents
     * @see external:HTMLElement#parents
     */
    SVGElement.prototype.parents = HTMLElement.prototype.parents;

}