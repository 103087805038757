//==================================================================================================
//
//	Remove a given class
//
//==================================================================================================

if (HTMLElement.removeClass === undefined) {

    /**
     * Remove a class from an element
     * @function external:HTMLElement#removeClass
     * @param {String} className - Class to be removed
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".button");
     * element.removeClass("active");
     */
    HTMLElement.prototype.removeClass = function(className) {
        const element = this;

        if (element.classList.contains(className) === true) {
            element.classList.remove(className);
        }

        return element;
    };

    /**
     * @function external:SVGElement#removeClass
     * @see external:HTMLElement#removeClass
     */
    SVGElement.prototype.removeClass = HTMLElement.prototype.removeClass;

}