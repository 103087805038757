//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";
import { keyAccessibility } from "helpers/keyAccessibility";

//==================================================================================================
//
//	Animate Sub Menu In
//  This is the function that is called to animate the sub menu of a tray to an open state
//
//==================================================================================================

/**
 * Function used to animate in a desired sub menu to open
 * @function
 * @ignore
 * @param {Object} instance - The Mega nav instance
 * @param {HTMLElement} trayDetails - The tray details object.
 * @param {HTMLElement} SubMenuData - The sub menu to be animated in.
 * @example
 * // Cache the instance
 * const megaMenu = this;
 *
 * // Cache instance
 * const megaMenu = this;
 *
 * // Loop through tray items
 * megaMenu.trayItems.forEach(function(currentTrayItem) {
 *
 * // Set holder for tray details
 * const trayDetails = currentTrayItem.trayDetails;
 *
 * // Loop through sub menu's
 * trayDetails.subMenus.forEach(function(currentSubMenu) {
 *
 *      // Attach Click event to trigger
 *      currentSubMenu.trigger.addEventListener("click", function handleClick(event) {
 *          // Prevent Default
 *          event.preventDefault();
 *
 *          // Open the sub menu
 *          animateSubMenuIn(megaMenu, trayDetails, currentSubMenu);
 *      });
 * });
 *
 */
export const animateSubMenuIn = (instance, trayDetails, subMenuData) => {

    // Cache Instance
    const megaMenu = instance;

    // Set Play to active
    megaMenu.activeTransition = true;

    // Set active sub menu
    megaMenu.activeSubMenu = {
        "tray": trayDetails,
        "menu": subMenuData
    };

    // Create timeline instance
    const timeline = anime.timeline({
        autoplay: false
    });

    // Fade Out Promo Column
    timeline.add({
        targets: trayDetails.columns.promoColumn,
        opacity: 0,
        duration: 300,
        easing: 'linear',
        complete: function() {
            // Set column to display none
            trayDetails.columns.promoColumn.style.display = "none";
        }
    });

    // Animate Arrow
    timeline.add({
        begin: function() {
            // Set trigger as active
            subMenuData.trigger.setAttribute("data-active", "");
            // Set submenu as active
            subMenuData.element.setAttribute("data-active", "");
            // Set list to open
            trayDetails.columns.navColumn.setAttribute("data-open", "");
        },
        targets: subMenuData.trigger,
        translateX: "20px",
        duration: 100,
        easing: 'linear',
        offset: "-=200"
    });

    // Animate In Sub Menu Items
    timeline.add({
        begin: function() {
            subMenuData.element.style.display = "flex";
        },
        targets: subMenuData.element,
        opacity: 1,
        duration: 200,
        easing: "linear",
        complete: function() {
            // Set transition to inactive
            megaMenu.activeTransition = false;
        }
    });

    // Animate In Sub Nav Columns
    timeline.add({
        begin: function() {
            // Offset columns
            subMenuData.subNavColumns.forEach(function(currentItem) {
                // Offset the current item
                // This Fixes a hardware acceleration issue in chrome that prevents
                // the first animation cycle from happening
                currentItem.style.transform = "translateX(50%)";
            });

            // Define sub menu size (+ 120 for padding 60px top and bottom);
            const subMenuSize = subMenuData.element.offsetHeight + 120;

            // Check if resize is required
            if (subMenuSize > megaMenu.activeItem.trayOriginalSize) {
                // Find content block
                const contentElement = megaMenu.activeItem.trayElement.querySelector(".mega-menu-tray__content");
                contentElement.style.height = subMenuSize + "px";

                // Animate the height of the menu to suit the sub menu
                anime({
                    targets: megaMenu.activeItem.trayElement,
                    height: subMenuSize,
                    easing: "linear",
                    duration: 200
                });
            }

        },
        targets: subMenuData.subNavColumns,
        translateX: "0%",
        opacity: 1,
        duration: 300,
        easing: "linear",
        offset: "-=160"
    });

    // If the sub menu is extended (More than 10 items)
    if(subMenuData.extended === true) {

        // Add translate to button
        timeline.add({
            targets: trayDetails.columns.navColumn.querySelector(".mega-menu-tray__nav"),
            paddingLeft: "60px",
            duration: 100,
            easing: "linear",
            offset: "-=500"
        });

        // Animate Out Promo
        timeline.add({
            targets: trayDetails.columns.infoColumn,
            opacity: 0,
            duration: 200,
            easing: "linear",
            offset: "-=500"
        });

        // Animate Tray To Hide Promo
        timeline.add({
            targets: trayDetails.trayElementContainer,
            translateX: "-38%",
            duration: 300,
            easing: "linear",
            offset: "-=300",
            complete: function() {

                // Define custom function to close menu
                megaMenu.backButtonHandler = function(event) {
                    // If the event is a keypress or click
                    // Supports Space & Enter press
                    if(event.type === "click" || keyAccessibility.isSpaceOrEnter(event)) {
                        // Prevent Default
                        event.preventDefault();

                        // If trigger exists
                        if(megaMenu.activeSubMenu !== undefined) {
                            // Check if key press
                            if(event.type !== "click") {
                                // Return focus
                                megaMenu.activeSubMenu.menu.trigger.focus();
                            }

                            // Close the sub menu
                            megaMenu.closeSubLevel();

                            // Remove the keydown event
                            for (var i = 0; i < trayDetails.navTrayBackButton.length; i++)
                            {
                                trayDetails.navTrayBackButton[i].removeEventListener("keydown", megaMenu.backButtonHandler);
                            }
                        }
                    }
                };

                // Bind click event
                for (var i = 0; i < trayDetails.navTrayBackButton.length; i++) {
                    trayDetails.navTrayBackButton[i].addEventListener("click", megaMenu.backButtonHandler, { once: true });
                }

                // Bind keyboard press
                for (var i = 0; i < trayDetails.navTrayBackButton.length; i++) {
                    trayDetails.navTrayBackButton[i].addEventListener("keydown", megaMenu.backButtonHandler);
                }
            }
        });

    }

    // Add Timeline to instance
    megaMenu.anime = timeline;

    // Play timeline
    megaMenu.anime.play();
};