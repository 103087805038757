//==================================================================================================
//	Dependencies
//==================================================================================================
import { AnimatedProgressBar } from 'objects/AnimatedProgressBar';
import { toArray } from "helpers/shared/toArray";
import { keyAccessibility } from "helpers/keyAccessibility"

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * Provides custom pagination controls for the hero carousel
 * @constructor
 * @param {BasicCarouselJS} carousel - The instance of the carousel which this controls
 * @param {HTMLElement} container - The element which contains the hero carousel
 * @example
 * const carouselWrapper = document.querySelector("[data-hero-carousel]");
 * const carouselContainer = carouselWrapper.querySelector("[data-carousel]");
 * let heroCarouselPagination;
 * let carouselCallback = () => {
 *      if (heroCarouselPagination) {
 *          heroCarouselPagination.setPosition(carousel.position);
 *      }
 * };
 * const carousel = new BasicCarouselJS(carouselContainer, null, null, carouselCallback);
 * heroCarouselPagination = new HeroCarouselPagination(carousel, carouselWrapper);
 */
export const HeroCarouselPagination = function (carousel, container) {
    const heroCarouselPagination = this;

    heroCarouselPagination.carousel = carousel;

    const paginationWrapper = container.querySelector("[data-hero-carousel-navigation]");

    const pageDuration = parseInt(paginationWrapper.getAttribute("[data-page-duration]") || 5000, 10);

    const paginationControls = toArray(paginationWrapper.children);

    heroCarouselPagination.pages = paginationControls.map((paginationControl) => {
        const animatedElement = paginationControl.querySelector(".hero-carousel__pagination-progress");

        const onAnimationComplete = () => {
            const newPosition = this.position + 1;
            this.setPosition(newPosition);
        };

        const progressBar = new AnimatedProgressBar(animatedElement, onAnimationComplete, pageDuration);

        const page = { paginationControl, progressBar };

        return page;
    });

    heroCarouselPagination.pages.forEach((page) => {
        page.paginationControl.addEventListener("click", () => { this.setPage(page); });
        page.paginationControl.addEventListener("keyup", (event)=> {
            if(keyAccessibility.isSpaceOrEnter(event)) {
                // Prevent Default
                event.preventDefault();
                // toggle the search box
                this.setPage(page);
            }
        });
    });

    heroCarouselPagination.setPosition(0);
};

//==================================================================================================
//  Set the current page by position of the paginaton control
//==================================================================================================
/**
 * @memberOf HeroCarouselPagination
 */
HeroCarouselPagination.prototype.setPosition = function (newPosition) {
    //Wrap around to the start if caller sets position above page count
    newPosition = newPosition % this.pages.length;

    const page = this.pages[newPosition];
    this.setPage(page);
};

//==================================================================================================
//  Set the current page
//==================================================================================================
/**
 * @memberOf HeroCarouselPagination
 */
HeroCarouselPagination.prototype.setPage = function setPage(page) {
    const currentPage = this.pages[this.position];

    //If the user clicks on the control for the current page, do nothing
    if (currentPage === page) {
        return;
    }

    //Stop all animations
    this.pages.forEach((p) => {
        p.progressBar.stop();
    });

    this.position = this.pages.indexOf(page);

    // Go to the desired slide
    this.carousel.goTo(this.position);

    //Start the animation for the new active page
    page.progressBar.start();
};