//==================================================================================================
//
//	Resize event handler
//
//==================================================================================================

//==================================================================================================
//	Private variables
//==================================================================================================
/**
 * Queue of callbacks to be called when a resize event is fired
 * @private
 * @memberof Resize
 */
const callbacks = [];


//==================================================================================================
//	We will assume if this object is imported the handler should be bound
//==================================================================================================
/**
 * Event listener added to the document to iterate through our callbacks
 * @private
 * @function listener
 * @memberof Resize
 * @param {Event} event - Event object passed to our callback by the event listener
 */
function listener(event) {
    callbacks.forEach(function iterateCallbacks(callback) {
        if (callback) {
            callback(event);
        }
    });
}

window.addEventListener("resize", listener);


//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * Wrapper for the browser resize event.
 * This has the added benefit of only one event handler being bound, and the ability to add/remove individual callbacks
 * @constructor
 * @param {Function} callback - Callback to be added to the resize queue
 * @property {Function} index - Position of our callback within the resize queue
 * @property {Function} callback - Local reference to the callback passed on instantiation
 * @example
 * let callback = function(event) { console.log(event); }
 * let resize = new Resize(callback);
 */
export const Resize = function(callback) {
    const resize = this;

    resize.callback = callback;
    resize.index = undefined;

    if (callback) {
        resize.attach(callback);
    }
};


//==================================================================================================
//	Method for adding the callback from this instance of Resize to the queue
//==================================================================================================
/**
 * Method to attach our callback to the resize queue
 * This is done automatically when the Resize object is instantiated
 */
Resize.prototype.attach = function() {
    const resize = this;

    if (resize.index === undefined) {
        resize.index = callbacks.length;
        callbacks[resize.index] = resize.callback;
    }

    return resize;
};


//==================================================================================================
//	Method for removing the callback from the queue
//==================================================================================================
/**
 * Method to detach our callback to the resize queue
 */
Resize.prototype.detach = function() {
    const resize = this;

    if (resize.index !== undefined) {
        callbacks[resize.index] = undefined;
        resize.index = undefined;
    }

    return resize;
};


//==================================================================================================
//	Expose our private queue array
//==================================================================================================
/**
 * Exposes our private callback collection
 * @returns {Array} - Callback collection
 */
Resize.prototype.getCallbacks = function() {
    return callbacks;
};