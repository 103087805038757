//==================================================================================================
//
//	Toggle a class
//
//==================================================================================================

if (HTMLElement.toggleClass === undefined) {

    /**
     * Add a given class if it is not already present, or remove if it exists.
     * @function external:HTMLElement#toggleClass
     * @param {String} className - Class to be toggled
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".button");
     * element.toggleClass("active");
     */
    HTMLElement.prototype.toggleClass = function(className) {
        const element = this;

        element.classList.toggle(className);
        return element;
    };

    /**
     * @function external:SVGElement#toggleClass
     * @see external:HTMLElement#toggleClass
     */
    SVGElement.prototype.toggleClass = HTMLElement.prototype.toggleClass;

}