//==================================================================================================
//
//	Get method - XMLHTTP v2
//
//==================================================================================================
import { CatchError } from "objects/shared/CatchError";

/**
 * @module module:get
 */

//==================================================================================================
//	Method
//==================================================================================================
/**
 * Make XMLHttpRequest (v2) using GET.
 * @function
 * @param {String} url - URL for the resource to be requested
 * @param {String} [content_type] - Content type of the resource to be returned, defaults to "text"
 * @param {String} [timeout] - Timeout value (in ms) to be used for the request
 * @param {Boolean} [cors] - Identify whether this a cross domain request which requires credentials
 * @returns {Promise}
 * @example
 * let request = get("https://myurl.com");
 * request.then(
 * 	function fulfill() {
 *		//	Do something when it succeeds
 * 	},
 * 	function reject() {
 * 		//	Do something when it fails
 * 	}
 * );
 */
export const get = function(url, content_type, timeout, cors) {
    return new Promise(
        function ajaxGetPromise(fulfill, reject) {
            const xhr = new XMLHttpRequest();
				xhr.open('GET', url, true);

				//	Options
				xhr.responseType = content_type || 'text';
				xhr.withCredentials = cors || false;
				xhr.timeout = timeout || 10000;

					//	On load
					xhr.onload = function() {
						if (xhr.status === 200) {
							return fulfill(xhr.response);
						}
						else {
							return new CatchError(xhr, xhr.response, function errorHandling() {
								//	This is bound to CatchError instance by constructor
								reject(xhr, this);
							});
						}
					};

					//	On error
					xhr.onerror = function(error) {
						return new CatchError(xhr, error, function errorHandling() {
							//	This is bound to CatchError instance by constructor
							reject(xhr, this);
						});
					};

					//On timeout
					xhr.ontimeout = function() {
						reject(xhr, this);
					}

				//	Fire request
				return xhr.send();

        }
    );
};