//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { interactiveState } from "helpers/interactiveState";

// Import animations
import { openMenu } from "helpers/animations/megaMenuMobile/openMenu";
import { closeMenu } from "helpers/animations/megaMenuMobile/closeMenu";
import { openSubMenu } from "helpers/animations/megaMenuMobile/openSubMenu";
import { closeSubMenu } from "helpers/animations/megaMenuMobile/closeSubMenu";
import { openTrayMenu } from "helpers/animations/megaMenuMobile/openTrayMenu";
import { closeTrayMenu } from "helpers/animations/megaMenuMobile/closeTrayMenu";

//==================================================================================================
//
//	Mega Menu Mobile
//
//==================================================================================================

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * This is used to provide navigation item tracking within the navigation to show the current mouseover target
 * @constructor
 * @param {HTMLElement} element - The mega menu element.
 * @param {HTMLElement} trigger - The trigger element for the mega menu.
 *
 * @property {HTMLElement} element - The mega menu element.
 * @property {HTMLElement} trigger - The trigger element for the mega menu.
 * @property {Boolean} active - The status of the menu [true = active, false = inactive].
 * @property {Boolean} hardReset - Defines if animations should be played over 0s - This is used when the requirement to quickly reset nav states is required.
 * @property {Object} tray - An object containing details fo the tray
 * @property {HTMLElement} tray.element - The tray element.
 * @property {HTMLElement} tray.container - The tray container element.
 * @property {Array} tray.items - An array of tray items.
 * @property {HTMLElement} tray.items.element - The tray item element.
 * @property {HTMLElement} tray.items.trigger - The tray item trigger element.
 * @property {Object} tray.items.subMenu - The Sub menu relevant to the tray item.
 * @property {HTMLElement} tray.items.subMenu.container The sub menu container element.
 * @property {Array} menuItems - An array of primary menu items.
 * @property {Object} menuItems.primaryNavItem - The primary nav item details.
 * @property {HTMLElement} menuItems.primaryNavItem.trigger - The primary nav item trigger element.
 * @property {HTMLElement} menuItems.primaryNavItem.element - The primary nav item element.
 * @property {Object} menuItems.tray - An object to define the tray.
 * @property {HTMLElement} menuItems.tray.element - The tray element.
 * @property {HTMLElement} menuItems.tray.containerSize - The tray container size.
 * @property {Array} menuItems.tray.items - An array of tray menu items
 * @property {HTMLElement} menuItems.tray.items.trigger - The tray item trigger element.
 * @property {HTMLElement} menuItems.tray.items.element - The tray item element.
 * @property {HTMLElement} menuItems.tray.items.subMenu - The tray item sub menu element.
 * @property {Boolean} sizeSet - Defines if the size has been set.
 * @property {Object} activeItem - The active item object.
 * @property {Object} activeSubMenu - The active sub menu item object.
 *
 * @example
 * // Declare variable to stop resize unless majority change
 * let browserSize = 0;
 *
 * // Look up the dom for mega menus
 * const megaMenus = toArray(document.querySelectorAll("[data-mega-menu-mobile]"));
 *
 * // If the mega menu exists
 * if(megaMenus.length > 0) {
 *      // Loop through the elements
 *      megaMenus.forEach(function(currentMenu) {
 *
 *          // Look up the trigger
 *          const trigger = document.querySelector("[data-mega-menu-mobile-trigger]");
 *
 *          // Create a new mega menu
 *          const megaMenu = new MegaMenuMobile(currentMenu, trigger);
 *      });
 * }
 */
export const MegaMenuMobile = function(element, trigger) {
    // Cache instance
    const megaMenu = this;

    // Set properties
    megaMenu.element = element;
    megaMenu.trigger = trigger;
    megaMenu.active = false;
    megaMenu.hardReset = false;

    // Initiate mega menu
    megaMenu.init();
};

//==================================================================================================
//	Init
//==================================================================================================
/**
 * Initiates the Mega Menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.init = function() {
    // Cache instance
    const megaMenu = this;

    // Define the nav
    megaMenu.defineItems();

    // Set Heights
    megaMenu.setHeights();

    // Attach Events
    megaMenu.attachEvents();

};

//==================================================================================================
//	Define Items
//==================================================================================================
/**
 * Used to define the mega menu items
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.defineItems = function() {
    // Cache instance
    const megaMenu = this;

    // Create placeholder for items array
    const menuItems = [];

    // Look up the primary nav items
    const primaryItems = toArray(megaMenu.element.querySelectorAll("[data-primary-nav-item]"));

    // Loop through primary items
    primaryItems.forEach(function(currentPrimaryItem) {
        // Variables
        const menuItem = {
            primaryNavItem: {
                trigger: currentPrimaryItem.querySelector('a'),
                element: currentPrimaryItem
            },
            tray: {
                element: undefined,
                items: []
            }
        };

        // Check for a tray menu
        menuItem.tray.element = currentPrimaryItem.querySelector("[data-tray]");
        menuItem.tray.container = currentPrimaryItem.querySelector(".mega-menu-mobile__tray-container");

        // If there is a tray
        if(menuItem.tray.container) {

            // Look up sum items of the tray
            const trayItems = toArray(menuItem.tray.element.querySelectorAll("[data-tray-item]"));

            // Loop through tray items
            trayItems.forEach(function(currentMenuItem) {
                // Look for a submenu
                const submenuElement = currentMenuItem.querySelector("[data-subnav]");

                // Create placeholder data for submenu
                let subMenu = undefined;

                // If there is a submenu
                if(submenuElement) {
                    // Create submenu data
                    subMenu = {
                        container: submenuElement
                    };
                }

                // Create tray item data
                const trayData = {
                    element: currentMenuItem,
                    trigger: currentMenuItem.querySelector("a"),
                    subMenu: subMenu
                };

                // Push to tray items array
                menuItem.tray.items.push(trayData);
            });
        }

        // Push menu item to array
        menuItems.push(menuItem);

    });

    // store menu items to object
    megaMenu.menuItems = menuItems;

};

//==================================================================================================
//	Set Heights
//==================================================================================================
/**
 * Used to set the define the height of each mega menu tray
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.setHeights = function() {
    // Cache instance
    const megaMenu = this;

    // Perform document interactive event
    const waitForInteractive = new interactiveState();

    // Once the dom is interactive proceed
    waitForInteractive.then(() => {

        // Store original height of trays
        megaMenu.menuItems.forEach(function(currentMenuItem) {
            // Check if there is a tray
            if(currentMenuItem.tray.container !== null && currentMenuItem.tray.container !== undefined) {
                // Store the client height
                const containerSize = currentMenuItem.tray.container.clientHeight;

                // check container height is valid
                if(containerSize > 0) {
                    // Store in the object
                    currentMenuItem.tray.containerSize = containerSize;
                }
            }
        });
    });

};

//==================================================================================================
//	Open
//==================================================================================================
/**
 * Used to open the menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.open = function() {
    // Cache instance
    const megaMenu = this;

    // Open the menu
    openMenu(megaMenu);

    // Set Active to true
    megaMenu.active = true;
};

//==================================================================================================
//	Close
//==================================================================================================
/**
 * Used to close the menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.close = function() {
    // Cache instance
    const megaMenu = this;

    // Close the menu
    closeMenu(megaMenu);

    // Set active to false
    megaMenu.active = false;
};

//==================================================================================================
//	Open Tray Menu
//==================================================================================================
/**
 * Used to open the menu tray
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.openTrayMenu = function() {
    // Cache instance
    const megaMenu = this;
    // Open the tray menu
    openTrayMenu(megaMenu);
};

//==================================================================================================
//	Close Tray Menu
//==================================================================================================
/**
 * Used to close the menu tray
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.closeTrayMenu = function() {
    // Cache instance
    const megaMenu = this;
    // Close the tray menu
    closeTrayMenu(megaMenu);
};

//==================================================================================================
//	Open Sub Menu
//==================================================================================================
/**
 * Used to open the sub menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.openSubMenu = function() {
    // Cache instance
    const megaMenu = this;

    // Open the submenu
    openSubMenu(megaMenu);
};

//==================================================================================================
//	Close Sub Menu
//==================================================================================================
/**
 * Used to close the sub menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.closeSubMenu = function() {
    // Cache instance
    const megaMenu = this;
    // Close the submenu
    closeSubMenu(megaMenu);
};

//==================================================================================================
//	Handle Resize
//==================================================================================================
/**
 * Used to handle the menu on resize
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.handleResize = function() {
    // // Cache instance
    const megaMenu = this;

    // IF the size set is not true and the width of the window is less than 1140
    if(megaMenu.sizeSet !== true && window.innerWidth < 1140) {
        // Fire set heights
        megaMenu.setHeights();
        // Set size set to true
        megaMenu.sizeSet = true;
    }
    else if(window.innerWidth >= 1140) {
        // Close the menu
        megaMenu.close();
    }

};

//==================================================================================================
//	Attach Events
//==================================================================================================
/**
 * Used to attach events to the menu
 * @memberOf MegaMenuMobile
 */
MegaMenuMobile.prototype.attachEvents = function() {
    // Cache instance
    const megaMenu = this;

    // Loop through the menu items
    megaMenu.menuItems.forEach(function(currentMenuItem) {

        // If the menu item has a tray
        if(currentMenuItem.tray.container !== null && currentMenuItem.tray.container !== undefined) {
            // Attach click event to the primary nav item (Open / Close)
            currentMenuItem.primaryNavItem.trigger.addEventListener("click", function(event) {
                // Prevent default
                event.preventDefault();

                // Check if menu is active
                if(megaMenu.activeItem !== undefined) {

                    // Check if submenu is active
                    if(megaMenu.activeSubMenu !== undefined) {
                        megaMenu.closeSubMenu();
                    }
                    // Check if tray is open
                    else if(megaMenu.activeItem.primaryNavItem === currentMenuItem.primaryNavItem) {
                        // Close the menu
                        megaMenu.closeTrayMenu();
                    }
                    else {
                        // Open the menu
                        megaMenu.openTrayMenu();
                    }
                }
                else {
                    // Set Active Item
                    megaMenu.activeItem = currentMenuItem;
                    // Open the menu
                    megaMenu.openTrayMenu();
                }
            });

            // If a container exists
            if(currentMenuItem.tray.container !== null) {
                // Loop through tray nav item
                currentMenuItem.tray.items.forEach(function(currentTrayItem) {
                    // If submenu is present
                    if(currentTrayItem.subMenu !== undefined) {
                        // Hook Up Tray Item
                        currentTrayItem.trigger.addEventListener("click", function(event) {
                            // Prevent Default
                            event.preventDefault();

                            // Check if current item is active
                            if(megaMenu.activeSubMenu === currentTrayItem) {
                                // Cancel execution
                                megaMenu.closeSubMenu();
                            }
                            else {
                                // Set active submenu
                                megaMenu.activeSubMenu = currentTrayItem;
                                // Open the submenu
                                megaMenu.openSubMenu();
                            }
                        });
                    }
                });
            }

        }
    });
};