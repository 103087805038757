//==================================================================================================
//
//	Find the first ancestor node to match a given selector
//
//==================================================================================================

if (HTMLElement.firstParent === undefined) {

    /**
     * Find the first ancestor node to match a given selector
     * @function external:HTMLElement#firstParent
     * @param {String} selector - CSS selector used to match our ancestor elements
     * @returns {HTMLElement|null}
     * @example
     * let element = document.querySelector(".button");
     * let parent = element.firstParent(".container");
     * if (parent) {
     *  //  Do something
     * }
     */
    HTMLElement.prototype.firstParent = function(selector) {
        const element = this;
        let parent = element.parentNode;

        while(parent && parent.matches) {

            if (parent.matches(selector)) {
                return parent;
            }

            parent = parent.parentNode;

        }

        return null;
    };


    /**
     * @function external:SVGElement#firstParent
     * @see external:HTMLElement#firstParent
     */
    SVGElement.prototype.firstParent = HTMLElement.prototype.firstParent;

}