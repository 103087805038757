import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";

new DOMReady(() => {
    //TODO: import from SCSS (build tools do not support and no time to address)
    const buttonWidth = 20;
    document.documentElement.classList.add('js-enabled');

    const rangeInputs = toArray(document.querySelectorAll(".FormRange__Input"));

    const forms = toArray(document.querySelectorAll("form"));

    const changeHandlers = [];

    forms.forEach((form)=>{
        form.addEventListener("reset",()=>{
            //force a full refresh on form reset
            setTimeout(()=>{
                changeHandlers.forEach(h=>h());
            });
        });
    });

    rangeInputs.forEach((rangeInput) => {
        const valueDisplay = document.createElement("div");
        valueDisplay.setAttribute("class", "Range__current-value");
        const container = rangeInput.parentElement;

        container.appendChild(valueDisplay);

        const minimumValue = rangeInput.getAttribute("min");
        const maximumValue = rangeInput.getAttribute("max");

        container.style.setProperty("--min", minimumValue);
        container.style.setProperty("--max", maximumValue);

        const changeHandler = () => {
            const currentValue = rangeInput.value;
            rangeInput.style.setProperty("--val", + currentValue);
            valueDisplay.innerText = currentValue;

            //IE11 support - set transform from script (no css var support)
            const effectiveRangeWidth = rangeInput.clientWidth - buttonWidth;
            const ratio =(currentValue - minimumValue) / (maximumValue - minimumValue);
            const offset = buttonWidth / 2 - valueDisplay.clientWidth /2 + 2 //2px = border width;

            const left = effectiveRangeWidth * ratio + offset;

            valueDisplay.style.setProperty("left", `${left}px`);
        };

        changeHandlers.push(changeHandler);

        rangeInput.addEventListener("input", changeHandler, false);

        //IE11 doesn't have input event
        rangeInput.addEventListener("change", changeHandler, false);

        changeHandler();
    });
});