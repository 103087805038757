function isSpace(event) {
    const triggers = [" ", "Space", "Spacebar"];

    return test(event, triggers);
}

function isSpaceOrEnter(event) {
    return isSpace(event) || isEnter(event);
}

function isEnter (event) {
    const triggers = ["Enter"];

    return test(event, triggers);
}

function isDownArrow(event) {
    const triggers = ["ArrowDown", "Down"];

    return test(event, triggers);
}

function isUpArrow(event) {
    const triggers = ["ArrowUp", "Up"];

    return test(event, triggers);
}

function test(event, triggers) {
    return triggers.indexOf(event.key) !== -1;
}

export const keyAccessibility = {
    isSpaceOrEnter : isSpaceOrEnter,
    isDownArrow : isDownArrow,
    isUpArrow : isUpArrow,
    isEnter : isEnter
};
