//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from "objects/shared/DOMReady";
import { toArray } from "helpers/shared/toArray";

//==================================================================================================
//	DOM ready logic
//==================================================================================================
new DOMReady(function languageSelector() {
    // Select the langauge selectors from the DOM
    const languageSelectors = toArray(document.querySelectorAll("[data-language-selector]"));

    // If langauge selectors are present
    if(languageSelectors.length > 0) {
        // Loop through language selectors
        languageSelectors.forEach(function(currentElement) {
            // Set a change event for the element
            currentElement.addEventListener("change", function(event) {
                // Get the selected element
                let selectedElement = event.target.selectedOptions;

                // If the element exits
                if(selectedElement.length > 0) {
                    // Get the first result
                    selectedElement = selectedElement[0];

                    // Extract the url path
                    const url = selectedElement.getAttribute("data-language-url");

                    // Navigate to the desired URL
                    window.location.href = url;

                }
                else {
                    console.warn("No url specified for the language selector");
                }
            });
        });
    }
});