//==================================================================================================
//
//	Wrap a given element in an optional tag, div by default.
//
//==================================================================================================
export const wrapElement = function (element, wrap_tag = 'div') {

	// create wrapper container
	var wrapper = document.createElement(wrap_tag);

	// insert wrapper before el in the DOM tree
	element.parentNode.insertBefore(wrapper, element);

	// move el into wrapper
	wrapper.appendChild(element);
};