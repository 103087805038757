//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Close
//  This is the function that is called to animate the menu tray to a closed state
//
//==================================================================================================

/**
 * Function used to animate the menu to closed
 * @function
 * @param {Object} instance - The mega menu instance to be used in the animation.
 * @param {Function} callback - The callback to be fired on complete.
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Create a new promise
 * const closeMenu = new Promise((resolve, reject) => {
 *      // Close the menu
 *      animateClose(megaMenu, resolve);
 * });
 *
 * // Once closed
 * closeMenu.then(() => {
 *      // Log to the console
 *      console.log("Animation Complete");
 * });
 */
export const animateClose = (instance, callback) => {
    // Cache instance
    const megaMenu = instance;

     // Create new  array for trays
     const trays = [];

     megaMenu.trayItems.forEach(function(currentItem) {
         trays.push(currentItem.trayElement);
     });

    // Define timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Animate the tray closed
    timeline.add({
        begin: function() {
            // Set transition to inactive
            megaMenu.activeTransition = true;
        },
        targets: trays,
        translateY: "-100%",
        duration: 200,
        easing: "linear",
        complete: function() {
            // Set transition to inactive
            megaMenu.activeTransition = false;

            // Loop through each tray
            trays.forEach(function(currentItem) {
                // Set the tray to hidden
                currentItem.style.visibility = "hidden";
                currentItem.style.opacity = 1;
            });

            // Clear the active mega menu item
            megaMenu.activeItem = undefined;

            // Set tray open to false
            megaMenu.trayOpen = false;

            // Set hardReset to false
            megaMenu.hardReset = false;

            // Check if there is a callback
            if(callback) {
                // Fire Callback
                callback();
            }
        }
    });

    // Play the timeline
    timeline.play();
};
