//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from "objects/shared/DOMReady";
import { toArray } from "helpers/shared/toArray";
import { MegaMenu } from "objects/MegaMenu";

//==================================================================================================
//	Mega Menu
//==================================================================================================
new DOMReady(function megaMenu() {
    // Look up the dom for mega menus
    const megaMenus = toArray(document.querySelectorAll("[data-mega-menu]"));

    // If the mega menu exists
    if(megaMenus.length > 0) {
        // Loop through the elements
        megaMenus.forEach(function(currentMenu) {

            // Create a new mega menu
            const megaMenu = new MegaMenu(currentMenu);

        });
    }
});