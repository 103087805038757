//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from "objects/shared/DOMReady";
import { Resize } from "objects/shared/Resize";
import { toArray } from "helpers/shared/toArray";
import { MegaMenuMobile } from "objects/MegaMenuMobile";

//==================================================================================================
//	Mega Menu
//==================================================================================================
new DOMReady(function megaMenu() {

    // Declare variable to stop resize unless majority change
    let browserSize = 0;

    // Look up the dom for mega menus
    const megaMenus = toArray(document.querySelectorAll("[data-mega-menu-mobile]"));

    // If the mega menu exists
    if(megaMenus.length > 0) {
        // Loop through the elements
        megaMenus.forEach(function(currentMenu) {

            // Look up the trigger
            const trigger = document.querySelector("[data-mega-menu-mobile-trigger]");

            // Create a new mega menu
            const megaMenu = new MegaMenuMobile(currentMenu, trigger);

            // Set click events for the trigger
            trigger.addEventListener("click", function(event) {
                // Prevent Default
                event.preventDefault();

                // Check if the menu is active
                if(megaMenu.active === false) {
                    // Open the menu
                    megaMenu.open();
                }
                else {
                    // Close the menu
                    megaMenu.close();
                }
            });

            // Add Resize event to reload the items
            new Resize(function() {

                // Define the positive size change
                const positiveSize = browserSize + 200;

                // Define the negative size change
                const negativeSize = browserSize - 200;

                // If the window size change is more than 200px and no sub item is present
                if(window.innerWidth > positiveSize || window.innerWidth < negativeSize) {

                    // If the mega nav has no active sub menu
                    if(megaMenu.activeSubMenu === undefined) {

                        // Set timeout to debounce
                        window.setTimeout(() => {
                            // Handle the resize
                            megaMenu.handleResize();
                        }, 500);

                        // Update the browser size
                        browserSize = window.innerWidth;
                    }
                }
            });

        });
    }
});