//==================================================================================================
//  Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { Resize } from 'objects/shared/Resize';
import { HeroCarouselPagination } from "objects/HeroCarouselPagination";
import  Siema  from "siema";

import { interactiveState } from "helpers/interactiveState";
import { touchEvents } from "helpers/carousel/touchEvents";
import { matchSlideHeights } from "helpers/carousel/matchSlideHeights";
import { SlideAccessiblity } from "objects/SlideAccessiblity";

//==================================================================================================
//
//  Hero Carousel
//
//==================================================================================================
new DOMReady(function initHeroCarousel() {

    // Query Select Carousel container
    const containers = toArray(document.querySelectorAll("[data-hero-carousel]"));

    // Check container exists
    if (containers.length > 0) {

        // Loop through container
        containers.forEach(function initCarousel(currentItem) {
            // Declare variables
            const carouselElement = currentItem.querySelector("[data-carousel]");

            // Detect the slides
            const slides = toArray(currentItem.querySelectorAll("[data-slide]"));

            // Instantiate slide tab manager
            const slideAccessiblity = new SlideAccessiblity(currentItem, "[data-slide]");

            // Find Pagination container
            const paginationContainer = currentItem.querySelector("[data-pagination]");

            // Create a new carousel
            const carousel = new Siema({
                selector: carouselElement,
                duration: 200,
                easing: 'ease-out',
                perPage: 1,
                startIndex: 0,
                draggable: false,
                threshold: 20,
                loop: true,
                onChange: () => {
                    // Handle user swipe
                    // If hero carousel pagination exists
                    if(heroCarouselPagination) {
                        // Set the position
                        heroCarouselPagination.setPosition(carousel.currentSlide);
                    }

                    //Disable tabbing on all but the current slide
                    slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);
                }
            });

            // Add custom property for number to slide
            carousel.numToSlide = 1;


            // Add Touch Events
            touchEvents(carouselElement, carousel);

            // Init pagination
            const heroCarouselPagination = new HeroCarouselPagination(carousel, paginationContainer);

            // Placeholder for timeout
            let resizeTimeout;

            // wait for interactive state
            const interactive = new interactiveState();

            // Disable tabbing on all but the current slide
            carousel.resizeHandler();
            slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);

            // When interactive
            interactive.then(() => {
                // Match Heights
                matchSlideHeights(slides);
            });

            // Create a new resize
            new Resize(() => {
                // Clear the previous timeout
                clearTimeout(resizeTimeout);

                // Set timeout
                resizeTimeout = setTimeout(() => {
                    matchSlideHeights(slides);
                    carousel.resizeHandler();
                    slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);
                }, 0);
            });

        });
    }

});