import Hammer from "hammerjs";

export const touchEvents = (element, instance) => {

    // Cache instance
    const carousel = instance;

    // Create new hammer event
    const touch = new Hammer(element);

    // Handle left swipe
    touch.on("swipeleft", () => {
        carousel.next(carousel.numToSlide);
    });

    // Handle right swipe
    touch.on("swiperight", () => {
        carousel.prev(carousel.numToSlide);
    });
};