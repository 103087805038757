//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { DropDownList } from 'objects/DropDownList';

//==================================================================================================
//
//	Drop Down List
//
//==================================================================================================
new DOMReady(function initDropDownList() {

    // Get all drop down elements
    const dropDownElements = toArray(document.querySelectorAll("[data-drop-down]"));

    // If the elements exist
    if(dropDownElements.length > 0) {

        // Loop through each element
        dropDownElements.forEach((currentElement) => {
            // Create new drop down list instance
            const dropDown = new DropDownList(currentElement);
        });

    }
});