import { toArray } from "helpers/shared/toArray";
import { get } from "helpers/shared/get";
import { keyAccessibility } from "helpers/keyAccessibility";
import anime from "animejs";
import { ImagePreloader } from "objects/ImagePreloader";

function selectCountry (url, name, data) {
    clearSuggestions(data);
    data.inputElement.value = name;
    //Do nothing if the current country is reselected
    if(data.countryDetailElement.getAttribute("data-selected-country") === name){
        return;
    }

    data.countryDetailElement.setAttribute("data-selected-country", name);

    replaceTextTransitionalElement(data.loadingElement, data.loadingElementTemplate, name);

    toggleTransitionalElement(data.errorElement, false);
    toggleTransitionalElement(data.loadingElement, true);

    get(url, null, 30000).then((response)=>{
        const newContent = document.createElement("div");
        newContent.innerHTML = response;

        //calback once the images are pre-loaded
        const showContent = ()=> {
            toggleTransitionalElement(data.loadingElement, false);

            let currentCountries = toArray(data.countryDetailElement.childNodes);
            currentCountries = currentCountries.filter((c)=>{
                return c.tagName && c.tagName === "DIV";
            });

            //Animate: 1) height of the container to the new content, 2) margin-top of new content to slide it down
            const currentHeight = data.countryDetailElement.offsetHeight;

            data.countryDetailElement.insertBefore(newContent, data.countryDetailElement.firstChild);

            const targetHeight = newContent.offsetHeight;

            anime({
                targets: newContent,
                "margin-top": [-1 * targetHeight, 0],
                opacity: [0, 1],
                easing:"easeInOutCubic",
                duration:1000,
                complete:()=>{
                    newContent.removeAttribute("style");
                }
            });

            anime({
                targets:data.countryDetailElement,
                height: [currentHeight, targetHeight],
                easing:"easeInOutCubic",
                duration:1000,
                complete:()=>{
                    data.countryDetailElement.removeAttribute("style");
                    currentCountries.forEach((c)=>{
                        data.countryDetailElement.removeChild(c);
                    });
                }
            });
        };

        //pre-load images to enable smooth slide down
        ImagePreloader(newContent, showContent);

    }, () => {
        //Error handling for country detail load
        data.countryDetailElement.innerHTML = "";
        replaceTextTransitionalElement(data.errorElement, data.errorElementTemplate, name);
        toggleTransitionalElement(data.errorElement, true);
        toggleTransitionalElement(data.loadingElement, false);
    });
}

function toggleTransitionalElement(element, display){
    //Elements start with style=display:none to prevent them displaying on slow connections
    element.removeAttribute("style");
    element.setAttribute("aria-hidden",!display);
}

function replaceTextTransitionalElement(element, template, country){
    element.innerHTML = template.replace(/\[Country\]/g, country);
}

function clearSuggestions(data) {
    data.inputElement.removeAttribute("data-user-keystrokes");
    data.searchResultsElement.innerHTML = "";
}

function findSuggestions(data) {
    //Clear out old suggestions
    clearSuggestions(data);

    const searchText = data.inputElement.value.toLowerCase();

    if(searchText.length < 2) {
        return;
    }

    const matches = data.countryData.filter((country)=>{
        return country.name.toLowerCase().startsWith(searchText);
    });

    matches.forEach((country)=>{
        const searchResult = document.createElement("div");
        searchResult.innerText = country.name;
        searchResult.setAttribute("data-url", country.url);
        searchResult.setAttribute("class","country-selector__search-result");
        //Use mousedown instead of click, otherwise blur on input element
        searchResult.addEventListener("mousedown", data.onCountryClick);
        data.searchResultsElement.appendChild(searchResult);
    });
}

function onArrowKeyPress(event, data) {
    const searchResults = toArray(data.searchResultsElement.childNodes);

    let selectedSuggestionIndex = searchResults.findIndex((searchResult)=> {
        const classList = toArray(searchResult.classList);
        return classList.indexOf("country-selector__search-result--selected") !== -1;
    });

    //Stop the cursor going to position 0
    event.preventDefault();

    //Do we have a selected suggestion
    if(selectedSuggestionIndex ==-1 ) {
        //Tuck away the user keystrokes so we can restore them on arrowing back to the input
        data.inputElement.setAttribute("data-user-keystrokes", data.inputElement.value);

        //Either select the first or last result depending on key
        selectedSuggestionIndex = keyAccessibility.isDownArrow(event) ? 0 : searchResults.length - 1;

        selectSuggestion(searchResults[selectedSuggestionIndex], data);

        return;
    }

    //Remove the highlighting on the previously highlighted suggestion
    searchResults[selectedSuggestionIndex].classList.remove("country-selector__search-result--selected");

    selectedSuggestionIndex += keyAccessibility.isDownArrow(event) ? 1 : -1;

    //Has the user keyed back onto the input box
    if(selectedSuggestionIndex < 0 || selectedSuggestionIndex >= searchResults.length){
        //Restore the user keystrokes
        data.inputElement.value = data.inputElement.getAttribute("data-user-keystrokes");
        //findSuggestions(data);//Not sure if this will be useful??
        return;
    }

    searchResults[selectedSuggestionIndex].classList.add("country-selector__search-result--selected");
    data.inputElement.value = searchResults[selectedSuggestionIndex].innerText;
}

function selectSuggestion(searchResultElement, data) {
    data.inputElement.value = searchResultElement.innerText;
    searchResultElement.classList.add("country-selector__search-result--selected");
}

function onEnterKeyPress(event, data) {
    event.preventDefault();

    const searchValue = data.inputElement.value.toLowerCase();

    //Check if we have a country matching the value of the search box, if so load it up
    const matchingCountry = data.countryData.find((country) => {
        return country.name.toLowerCase() === searchValue;
    });

    if(matchingCountry) {
        selectCountry(matchingCountry.url, matchingCountry.name, data);
    }
}

export const CountrySelector = function(countrySelectorElement) {
    const countryDataContainer = countrySelectorElement.querySelector("[data-country-list]");

    if(!countryDataContainer){
        return;
    }

    //Sort the countries by name
    let countryData = JSON.parse(countryDataContainer.innerText);

    countryData = countryData.sort(function(a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();

        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

    const inputElement = countrySelectorElement.querySelector("[data-country-input]");
    const searchResultsElement = countrySelectorElement.querySelector("[data-country-suggestions]");
    const countryDetailElement = countrySelectorElement.querySelector("[data-country-detail]");
    const errorElement = countrySelectorElement.querySelector("[data-country-error]");
    const loadingElement = countrySelectorElement.querySelector("[data-country-loading]");

    //Going to pass this to functions to make scoping easy
    const data = {countryDataContainer, countryData, inputElement, searchResultsElement, countryDetailElement,
        loadingElement, loadingElementTemplate : loadingElement.innerHTML,
        errorElement, errorElementTemplate: errorElement.innerHTML
    };

    data.onCountryClick = (event) => {
        selectCountry(event.target.getAttribute("data-url"), event.target.innerText, data);
    };

    inputElement.addEventListener("keyup",  (event) => {
        if(keyAccessibility.isDownArrow(event) || keyAccessibility.isUpArrow(event)){
            onArrowKeyPress(event, data);
        }
        else if(keyAccessibility.isEnter(event)){
            onEnterKeyPress(event, data);
        }
        else {
            findSuggestions(data);
        }
    });

    inputElement.addEventListener("keydown",  (event) => {
        if(keyAccessibility.isDownArrow(event) || keyAccessibility.isUpArrow(event)) {
            //Stop cursor jumping to start of input text
            event.preventDefault();
        }
    });

    //Hide search results if input loses focus
    inputElement.addEventListener("blur", (event) => {
        data.searchResultsElement.setAttribute("aria-hidden",true);
    });

    //Show search results when search box gains focus
    inputElement.addEventListener("focus", (event) => {
        data.searchResultsElement.setAttribute("aria-hidden",false);
    });
}