//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from "objects/shared/DOMReady";
import { NavigationTracker } from "objects/NavigationTracker";

//==================================================================================================
//	DOM ready logic
//==================================================================================================
new DOMReady(function navigationActiveTracker() {

    // Select the navigation from the DOM
    const navigation = document.querySelector("[data-mega-menu]");

    // If navigation exists
    if(navigation) {
        // Select the tracker from the dom
        const tracker = navigation.querySelector("[data-mega-menu-tracker]");

        // Check if tracker exists
        if(tracker === null) {
            console.warn("Tracker element not defined for navigation");
            return false;
        }

        // Create a new navigation tracker
        const navigationTracker = new NavigationTracker(navigation, tracker);

    }

});