//==================================================================================================
//
//  Figure animation. Add attribute data-figure-animation to a descendant of one with data-aos attribute
//
//==================================================================================================

import { DOMReady } from 'objects/shared/DOMReady';
import { InViewport } from 'objects/InViewport';
import anime from "animejs";
import { toArray } from "helpers/shared/toArray";

const figureAnimationSelector = "data-figure-animation";

//==================================================================================================
//	Private methods
//==================================================================================================
/**
 * Animates the numeric content of an element from 0 upwards
 * @private
 * @function figureAnimation
*  @param {HtmlElement} element - element to animate
 * */
function figureAnimation (element) {
    if (!element || !element.innerText) {
        return;
    }

    // Get all consecutive digits in the string
    // (This is what we consider a number)
    const innerTextNumbers = element.innerText.match(/\d+/gi);
    if (!innerTextNumbers) {
        return;
    }

    // Then split the string on these numbers, getting
    // all the remainder text
    const innerTextNotNumbers = element.innerText.split(/\d+/gi);

    anime({
        targets: element,
        opacity:1,
        easing:"easeInSine",
        duration:500,
        update:function(anim){

            // Animate each of the numbers
            const animatingNumbers = [];
            for (let i = 0; i < innerTextNumbers.length; i++){
                // Change the value as required for the animation
                animatingNumbers[i] = Math.ceil(innerTextNumbers[i] * anim.progress / 100);
            }

            // Re-combine the string with the new numbers
            let recombinedString = '';
            for (let i = 0; i < innerTextNotNumbers.length; i++){
                recombinedString += innerTextNotNumbers[i];
                if(typeof animatingNumbers[i] !== 'undefined'){
                    recombinedString += animatingNumbers[i];
                }
            }

            // Replace the innerText
            element.innerText = recombinedString;
        }
    });
}

/**
 * Called when a tracked element comes into view. This function checks if the element should be animated or has children that should be animated
 * @private
 * @function onVisible
*  @param {HtmlElement} element - element that has come into view
 * */
function onVisible(element){
    let figureAnimationElements = toArray(element.querySelectorAll(`[${figureAnimationSelector}]`));

    if (figureAnimationElements.length === 0 && element.hasAttribute(figureAnimationSelector)) {
        figureAnimationElements = [element];
    }

    figureAnimationElements.forEach((figureAnimationElement)=>{
        figureAnimation(figureAnimationElement);
    });
}

/**
 * DOMReady handler. Set up the InViewport callback
 */
new DOMReady(() => {
    InViewport(onVisible);
});