//==================================================================================================
//	Dependencies
//==================================================================================================
import { wrapElement } from "helpers/shared/wrapElement";
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';

//==================================================================================================
//
//	List Wrapper
//  Wrap all richtext lists in a div tag to aid content layout issues.
//
//==================================================================================================
new DOMReady(function unorderedListWrapper() {

	// Get all lists
	const unordered_lists = toArray(document.querySelectorAll(".richtext > ul, .richtext > ol"));

	// If lists exist
	if (unordered_lists.length > 0) {
		// Loop through the unordered lists
		unordered_lists.forEach(function (currentItem) {
			wrapElement(currentItem);
		});
	}

});