 //==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { SocialShareMenu } from "objects/SocialShareMenu";

//==================================================================================================
//
//	Social Share Menu
//
//==================================================================================================
new DOMReady(function initShareMenu() {

    // Lookup all social share menu elements
    const socialShareMenus = toArray(document.querySelectorAll("[data-social-menu]"));

    // If elements exist
    if(socialShareMenus.length > 0) {
        // Loop through the menus
        socialShareMenus.forEach(function(currentItem) {

            // Create a new social share menu
            const socialShareMenu = new SocialShareMenu(currentItem);

            // Close the menu by default (Applies relevant values to closed menu)
            socialShareMenu.close();
        });

    }


});