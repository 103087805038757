export const arrowEvents = (arrows, instance) => {
    // Cache instance
    const carousel = instance;

    // Check if the next arrow exists
    if(arrows.next) {
        // Add click event
        arrows.next.addEventListener("click", (event) => {
            // Prevent Default
            event.preventDefault();
            // Call next slide
            carousel.next(carousel.numToSlide);
        });
    }

    // Check if the prev arrow exists
    if(arrows.prev) {
        // Add Click Event
        arrows.prev.addEventListener("click", (event) => {
            // Prevent Default
            event.preventDefault();
            // Call previous slide
            carousel.prev(carousel.numToSlide);
        });
    }
};