//==================================================================================================
//
//	Empty element of all child nodes
//
//==================================================================================================

if (HTMLElement.empty === undefined) {

    /**
     * Empty element of all child nodes
     * @function external:HTMLElement#empty
     * @param {String} className - Class to be added to our element
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".main");
     * element.empty();
     */
    HTMLElement.prototype.empty = function() {
        const element = this;

        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }

        return element;
    };

    /**
     * @function external:SVGElement#empty
     * @see external:HTMLElement#empty
     */
    SVGElement.prototype.empty = HTMLElement.prototype.empty;

}