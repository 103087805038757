import { DiagnosticTool } from "objects/DiagnosticTool";
import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";

new DOMReady(()=>{
    const elements = toArray(document.querySelectorAll("[data-diagnostic-tool]"));

    elements.forEach((element)=>{
       new DiagnosticTool(element);
    });
});