import { toArray } from "helpers/shared/toArray";
import { get } from "helpers/shared/get";

export const ImagePreloader = function(element, callback) {
    const progressTrackers = [];

    const addTracker = (url)=>{
        progressTrackers.push({url:url, waiting:true});
    };

    const useElements = toArray(element.querySelectorAll("svg > use"));
    const backgroundImageElements = toArray(element.querySelectorAll("[style^='background-image:']"));
    const imageElements = toArray(element.querySelectorAll("img"));

    useElements.forEach((useElement)=>{
        const url = useElement.getAttribute("xlink:href");
        if(url) {
            addTracker(url);
        }
    });

    backgroundImageElements.forEach((backgroundImageElement)=>{
        let url = backgroundImageElement.style["background-image"].trim();

        url = url.substring(4, url.length-1);
        url = url.replace(/"|'/g, "");

        addTracker(url);
    });

    imageElements.forEach((imageElement)=>{
        let url = imageElement.getAttribute("src");

        addTracker(url);
    });

    progressTrackers.forEach((progressTracker)=>{
        const onComplete = ()=>{
            progressTracker.waiting = false;

            const waiting = progressTrackers.find((i)=>{return i.waiting});

            if (!waiting) {
                callback();
            }
        };

        get(progressTracker.url, null, 30000).then(onComplete,onComplete);
    });
}