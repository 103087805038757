//==================================================================================================
//
//	Interactive State
//  Used to create a promise which will return when the document ready state is interactive.
//
//==================================================================================================

/**
 * Interactive State
 * @returns {Promise} - Returns a promise which will fire on interactive state on the document.
 * @example
 * // Perform document interactive event
 * const waitForInteractive = new interactiveState();
 *
 * // Once the dom is interactive proceed
 * waitForInteractive.then(() => {
 *      console.log("The document is interactive");
 * });
 */
export const interactiveState = () => new Promise((resolve) => {
    // Check if the document is already interactive
    if(document.readyState === "complete") {
        // Resolve
        resolve();
    }else {
        // Set Custom event to watch for interactive call
        document.addEventListener("readystatechange", () => {
            // If the document is interactive
            if(document.readyState === "complete") {
                // Resolve
                resolve();
            }
        });
    }
});