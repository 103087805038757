//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Tray Open
//  This is the function that is called to animate the tray open
//
//==================================================================================================

/**
 * Function used to open tray menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // set active item
 * const activeItem = megaMenu.items[1];
 *
 * // Set Active Item
 * megaMenu.activeItem = activeItem;
 *
 * // Open the tray menu
 * openTrayMenu(megaMenu);
 */
export const openTrayMenu = (instance) => {
    // Cache instance
    const megaMenu = instance;

    // Cache active item
    const activeItem = megaMenu.activeItem;

    // Cache active nav item
    const activeNavItem = megaMenu.activeItem.primaryNavItem;

    // Create new timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Form array of all non active elements to be removed
    // Define menu items
    const menuItems = [];

    // Filter the array of items so does not contain the active one
    megaMenu.menuItems.filter(function(currentMenuItem) {
        if(currentMenuItem.primaryNavItem !== activeNavItem) {
            menuItems.push(currentMenuItem.primaryNavItem.element);
        }
    });

    // Calculate position of trigger from top of the element
    const triggerElementOffsetTop = activeNavItem.element.offsetTop;

    // Calculate Trigger offset (minus top padding)
    let triggerElementOffset = triggerElementOffsetTop - 60;

    // Check if not a valid number
    if(triggerElementOffset < 0) {
        triggerElementOffset = 0;
    }

    // Store offset to be used later
    activeItem.primaryNavItem.offset = triggerElementOffset;

    // Animate trigger
    timeline.add({
        begin: function() {
            // Set the item to active
            activeNavItem.element.setAttribute("data-active", "");
        },
        targets: activeNavItem.element,
        paddingLeft: "30px",
        duration: 200,
        easing: "linear"
    });

    // Animate Out Remaining Items
    timeline.add({
        targets: menuItems,
        translateX: "-120%",
        duration: 200,
        easing: "linear"
    });

    // Animate the trigger to the top of the page
    timeline.add({
        targets: activeNavItem.element,
        duration: 300,
        translateY: `-${triggerElementOffset}px`,
        easing: "linear",
        complete: function() {
            // Loop through menu items
            menuItems.forEach(function(currentItem) {
                // Display none the non active items
                currentItem.style.display = "none";
            });
            // Set the transform to none
            activeNavItem.element.style.transform = "none";
        }
    });

    timeline.add({
        targets: activeItem.tray.element,
        translateX: "130%",
        duration: 0,
        easing: "linear"
    });

    // Animate In Tray items
    timeline.add({
        begin: function() {
            // Update the tray to be visible
            activeItem.tray.element.style.display = "block";
            activeItem.tray.element.style.visibility = "visible";
            activeItem.tray.element.style.maxHeight = "none";
            activeItem.tray.element.style.opacity = 0;
        },
        targets: activeItem.tray.element,
        translateX: "0%",
        opacity: 1,
        duration: 300,
        easing: "linear"
    });

    // Play the timeline
    timeline.play();

};