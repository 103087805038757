/**
 * Component used to group a desired number of accordions together and provide accessibility functionality to the user.
 * @constructor
 * @param {HTMLObject} groupElement - The element to be used as the group wrapper.
 * @param {Array} accordions - The array of accordions relevant to the group.
 * @example
 * // Select all accordion groups
 * let accordionGroups = toArray(document.querySelectorAll("[data-accordion-group]"));
 *
 *  // Check if there are any accordion groups
 *  if(accordionGroups.length > 0) {
 *
 *      // Loop through accordion groups
 *      accordionGroups.forEach(function(currentGroup) {
 *
 *         // Select all accordion triggers
 *        let accordionTriggers = toArray(currentGroup.querySelectorAll("[data-accordion-trigger]"));
 *
 *        // Create array to store accordion trigger instances
 *        let accordionTriggersArray = [];
 *
 *        // Check accordion triggers exist
 *        if(accordionTriggers.length > 0) {
 *
 *             // Loop through accordion triggers
 *            accordionTriggers.forEach(function(currentAccordionTrigger, index) {
 *
 *                // Add position to accordion trigger
 *                currentAccordionTrigger.querySelector("[aria-controls]").setAttribute("data-accordion-position", index);
 *
 *                // Push instance to array
 *                accordionTriggersArray.push(currentAccordionTrigger);
 *
 *               });
 *
 *              // Create accordion group
 *               let accordionGroup = new AccordionGroup(currentGroup,accordionTriggersArray);
 *
 *         }else {
 *             // Throw a warning
 *             console.warn("Warning, Accordion group defined without accordion children");
 *         }
 *
 *       });
 *
 *  }
 *
 * @property {HTMLElement} element - The accordion element passed into the accordion constructor.
 * @property {Array} accordions - Array of accordions passed into the constructor.
 * @property {Integer} count - The accordions count.
 * @property {HTMLElement} active=undefined - The active accordion.
 * @property {Integer} currentPosition=0 - Current position of the user on the accordions.
 * @property {Integer} position - Position of the accordion with the current focus.
 */
export const AccordionGroup = function(groupElement, accordions) {
    // Cache Instance
    let accordionGroup = this;

    // Set properties
    accordionGroup.element = groupElement;
    accordionGroup.accordions = accordions;
    accordionGroup.count = accordions.length - 1;
    accordionGroup.active = undefined;
    accordionGroup.currentPosition = 0;

    // Define position
    Object.defineProperty(this, "position", {
        get: function() {
            // return currentPosition
            return this.currentPosition;
        },
        set: function(newPosition) {
            // If new positon is less than 0
            if(newPosition < 0) {
                // Set position to limit
                this.currentPosition = this.count;
            }
            // If new position more than limit
            else if(newPosition > this.count){
                // Set position to 0
                this.currentPosition = 0;
            }
            else {
                // Update position
                this.currentPosition = newPosition;
            };
            // Call the set Active method
            this.setActive();
        }
    });
    // Call Init
    accordionGroup.init();
}
/**
 * This method is used to initiate the accordion group.
 * @memberof AccordionGroup
 */
AccordionGroup.prototype.init = function() {
    // Cache Instance
    let accordionGroup = this;
    // Call accessibility
    accordionGroup.attachEvents();
}
/**
 * This method is used to toggle the next accordion element in the group.
 * @memberof AccordionGroup
 */
AccordionGroup.prototype.next = function() {
    // Cache instance
    let accordionGroup = this;
    // Request next item
    accordionGroup.position++;
}
/**
 * This method is used to toggle the previous accordion element in the group.
 * @memberof AccordionGroup
 */
AccordionGroup.prototype.prev = function() {
    // Cache instance
    let accordionGroup = this;
    // Request prev item
    accordionGroup.position--;
}
/**
 * This method is used to add toggle focus state on a desired element.
 * @memberof AccordionGroup
 */
AccordionGroup.prototype.setActive = function() {
    // Cache Instane
    let accordionGroup = this;

    // Set new active element
    accordionGroup.accordions[accordionGroup.currentPosition].button.focus();

}
/**
 * This method attached click and keyboard events to the accordion group.
 * @memberof AccordionGroup
 */
AccordionGroup.prototype.attachEvents = function() {
    // Cache Instance
    let accordionGroup = this;

    // Add keydown events
    accordionGroup.element.addEventListener("keydown", function(event) {

        // Get current position
        let newCurrentPosition = parseInt(event.target.getAttribute("data-accordion-position"));

        // Update if the current possition value is truthy or '0', else keep old
        if(newCurrentPosition || (newCurrentPosition === 0) ){
            accordionGroup.currentPosition = newCurrentPosition;
        }

        // Arrow Down
        if( event.key === "ArrowDown" || event.key === "Down" ) {

            // Prevent Default
            event.preventDefault();
            // Next
            accordionGroup.next();
        }
        // Arrow Up
        if( event.key === "ArrowUp" || event.key === "Up" ) {
            // Prevent Default
            event.preventDefault();
            // Prev
            accordionGroup.prev();
        }
        // Home
        if( event.key === "Home") {
            // Prevent Default
            event.preventDefault();
            // Update current position
            accordionGroup.currentPosition = 0;
            // Set active element
            accordionGroup.setActive();
        }
        // End
        if(event.key === "End") {
            // Prevent Default
            event.preventDefault();
            // Update current position
            accordionGroup.currentPosition = accordionGroup.count;
            // Set active element
            accordionGroup.setActive();
        }
        // Ctrl + Page up
        if(event.ctrlKey === true && event.key === "PageUp") {
            accordionGroup.prev();
        }

        // Ctrl + Page Down
        if(event.ctrlKey === true && event.key === "PageDown") {
            accordionGroup.next();
        }
    });
}