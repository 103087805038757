//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Open
//  This is the function that is called to animate the menu tray to an open state
//
//==================================================================================================

/**
 * Function used to animate the menu to open
 * @function
 * @param {Object} instance - The mega menu instance to be used to open the desired active item.
 * @ignore
 * @example
 * // Cache instance
 * const megMenu = this;
 *
 * // Set a new active item
 * megaMenu.activeItem = megaMenu.items[0];
 *
 * // Open the menu
 * animateOpen(megaMenu);
 */
export const animateOpen = (instance) => {
    // Cache instance
    const megaMenu = instance;

    // Cache active item
    const activeItem = megaMenu.activeItem;

    // Create new  array for trays
    const trayData = {
        trayElements: [],
        trayContainers: []
    };

    // Loop through each tray item
    megaMenu.trayItems.forEach(function(currentItem) {
        // Push the tray element to the trayElements array
        trayData.trayElements.push(currentItem.trayElement);

        // Push the tray container to the tray containers array
        trayData.trayContainers.push(currentItem.trayElementContainer);
    });

    // Check if menu is to be pulled down
    if(megaMenu.trayOpen === false) {
        // Animate trays open
        const timeline = new anime.timeline({
            autoplay: false
        });

        // Animate trays down
        timeline.add({
            begin: function() {
                // Set transition to inactive
                megaMenu.activeTransition = true;
                // Set the tray to open
                megaMenu.trayOpen = true;
                // Apply relevant styles to show the nav
                activeItem.trayElement.style.visibility = "visible";
                if(activeItem.trayElement) {
                    activeItem.trayElementContainer.style.opacity = 0;
                }
            },
            targets: trayData.trayElements,
            translateY: "-100%",
            duration: 0
        });

        timeline.add({
            targets: activeItem.trayElementContainer,
            opacity: 0,
            duration: 0
        });

        // Open the tray
        timeline.add({
            targets: trayData.trayElements,
            translateY: 0,
            duration: 200,
            easing: "linear",
            complete: function() {
                // Set transition to inactive
                megaMenu.activeTransition = false;
            }
        });

        // Animate in the container
        timeline.add({
            targets: activeItem.trayElementContainer,
            opacity: 1,
            duration: 200,
            offset: "-=200",
            easing: "linear"
        });

        // Play the timeline
        timeline.play();
    }
    // Fade out old item and fade new item in
    else {
        // Create a new timeline instance
        const timeline = new anime.timeline({
            autoplay: false
        });

        // Animate out the container
        timeline.add({
            targets: trayData.trayContainers,
            opacity: 0,
            duration: 150,
            easing: "linear"
        });

        // Animate the tray container in
        timeline.add({
            begin: function() {
                // Loop through each of the tray elements
                trayData.trayElements.forEach(function(currentItem) {
                    // Set the visibility to hidden
                    currentItem.style.visibility = "hidden";
                });
                // Set the current item to visible
                activeItem.trayElementContainer.opacity = 0;
                activeItem.trayElement.style.visibility = "visible";

                megaMenu.previousActive = undefined;

            },
            targets: activeItem.trayElementContainer,
            opacity: 1,
            delay: 70,
            duration: 200,
            easing: "linear"
        });

        // Play the timeline
        timeline.play();
    }
};