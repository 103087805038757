//==================================================================================
//	Dependencies
//==================================================================================
import { toArray } from "helpers/shared/toArray";
import { keyAccessibility } from "helpers/keyAccessibility"

/**
* Adds functionality to the social share menu.
* @constructor
* @param {HTMLElement} element - The social share menu element.
*
* @property {HTMLElement} element - The social share menu element.
* @property {HTMLElement} trigger - The activation button to open and close the menu.
* @property {HTMLElement} list - The list element of the menu.
* @property {Array} items - An array of social share items relevant to the social share menu.
* @property {Boolean} status - Stores the current active state of the menu.
* @property {GETTER/SETTER} active - Gets / Sets the menu active state.
*
* @example
* // Lookup all social share menu elements
* const socialShareMenus = toArray(document.querySelectorAll("[data-social-menu]"));
*
* // If elements exist
* if(socialShareMenus.length > 0) {
*     // Loop through the menus
*     socialShareMenus.forEach(function(currentItem) {
*
*         // Create a new social share menu
*         const socialShareMenu = new SocialShareMenu(currentItem);

*         // Close the menu by default (Applies relevant values to closed menu)
*         socialShareMenu.close();
*     );
* }
*
*/
export const SocialShareMenu = function(element) {
    // Cache instance
    const socialShareMenu = this;

    // Properties
    socialShareMenu.element = element;
    socialShareMenu.trigger  = element.querySelector("[data-social-menu-trigger]");
    socialShareMenu.list = element.querySelector("[data-social-menu-list]");
    socialShareMenu.items = toArray(socialShareMenu.list.querySelectorAll("[data-social]"));
    socialShareMenu.status = false;

    // Define active
    Object.defineProperty(this, "active", {
        get: function() {
            return socialShareMenu.status;
        },
        set: function(newstatus) {
            // Set new status
            socialShareMenu.status = newstatus;
            // Update the UI
            socialShareMenu.updateUI();
        }
    });

    // Initiate
    socialShareMenu.init();
};

/**
 * Initiates the social share menu
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.init = function() {
    // Cache instance
    const socialShareMenu = this;

    // Attach events
    socialShareMenu.attachEvents();
};

/**
 * Opens the social share menu
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.open = function() {
    // Cache instance
    const socialShareMenu = this;

    // Open the menu
    socialShareMenu.active = true;

};

/**
 * Closes the social share menu
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.close = function() {
    // Cache instance
    const socialShareMenu = this;

    // Close the menu
    socialShareMenu.active = false;

};

/**
 * Opens / closes the social share menu
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.toggle = function() {
    // Cache instance
    const socialShareMenu = this;

    // Check if menu is open
    if(socialShareMenu.active === true) {
        // Close the menu
        socialShareMenu.close();
    }
    else {
        // Open the menu
        socialShareMenu.open();
    }
};

/**
 * Adds events to the social share menu
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.attachEvents = function() {
    // Cache instance
    const socialShareMenu = this;

    // Attach click event
    socialShareMenu.trigger.addEventListener("keypress", function handleClick(event) {
        // Check if space or enter
        if(keyAccessibility.isSpaceOrEnter(event)) {
            // Toggle the menu
            socialShareMenu.toggle();
        }
    });

};

/**
 * Updates the social menu UI
 * @memberOf SocialShareMenu
 */
SocialShareMenu.prototype.updateUI = function() {
    // Cache instance
    const socialShareMenu = this;

    // Handle open state
    if(socialShareMenu.active === true) {
        // Set the list to visisble
        socialShareMenu.list.setAttribute("aria-hidden", "false");
        // Loop through and make items clickable
        socialShareMenu.items.forEach(function(currentItem) {
            // Make the item clickable
            currentItem.setAttribute("tabindex", "0");
        });
    }
    // Handle close state
    else {
        // Set the list to hidden
        socialShareMenu.list.setAttribute("aria-hidden", "true");
        // Loop through and remove items from tabindex
        socialShareMenu.items.forEach(function(currentItem) {
            // Remove item from tab index
            currentItem.setAttribute("tabindex", "-1");
        });
    }
};
