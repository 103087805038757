//==================================================================================================
//  Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import  Siema  from "siema";

import { pagination } from "helpers/carousel/pagination";
import { touchEvents } from "helpers/carousel/touchEvents";
import { arrowEvents } from "helpers/carousel/arrowEvents";
import { SlideAccessiblity } from "objects/SlideAccessiblity";
import { Resize } from "objects/shared/Resize";

//==================================================================================================
//
//  Reel Carousel
//
//==================================================================================================

new DOMReady(function initReelCarousel() {

    // Query Select Carousel conatiner
    const containers = toArray(document.querySelectorAll("[data-reel-carousel]"));

    // Check container exists
    if (containers.length > 0) {

        // Loop through container
        containers.forEach(function initCarousel(currentItem) {

            // Find the carousel
            const carouselElement = currentItem.querySelector("[data-carousel]");

            // Find the carousel arrows
            const arrows = {
                prev: currentItem.querySelector("[data-carousel-prev]"),
                next: currentItem.querySelector("[data-carousel-next]")
            };

            // Find the pagination component
            const paginationContainer = currentItem.querySelector("[data-pagination]");

            // Detect the slides
            const slides = currentItem.querySelectorAll("[data-slide]");

            // Instantiate slide tab manager
            const slideAccessiblity = new SlideAccessiblity(currentItem, "[data-slide]");

            // Create a new carousel
            const carousel = new Siema({
                selector: carouselElement,
                duration: 200,
                easing: 'ease-out',
                perPage: 1,
                startIndex: 0,
                draggable: false,
                threshold: 20,
                loop: true,
                onChange: () => {
                    // Loop through each carousel dot
                    carousel.carouselDots.forEach((currentPaginationItem) => {
                        // Remove the active attribute
                        currentPaginationItem.removeAttribute("data-active");
                    });

                    // If the carousel is more than the max (returns -1)
                    if(carousel.currentSlide < 0) {
                        // Set the last item as active
                        carousel.carouselDots[carousel.carouselDots.length - 1].setAttribute("data-active", "true");
                    }
                    else {

                        // If the num to slide is 1
                        if(carousel.numToSlide === 1) {
                            // Set the current slide as active
                            carousel.carouselDots[carousel.currentSlide].setAttribute("data-active", "true");
                        }
                        else {
                            // Calculate which pagination item to pic
                            const desiredDot = Math.ceil(carousel.currentSlide / carousel.numToSlide);
                            // Set the active item
                            carousel.carouselDots[desiredDot].setAttribute("data-active", "true");
                        }
                    }

                    //Disable tabbing on all but the current slide
                    slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);
                }
            });

            // Add custom property for number to slide
            carousel.numToSlide = 1;

            // Disable tabbing on all but the current slide
            slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);

            let resizeTimeout;
            new Resize(() => {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(()=>{
                    slideAccessiblity.restrictTabbingToSlides(carousel.currentSlide, carousel.numToSlide);
                },0);
            });

            // Add Touch Events
            touchEvents(carouselElement, carousel);

            // Add arrow events
            arrowEvents(arrows, carousel);

            // Create the carousel dots
            pagination(paginationContainer, slides, carousel);

        });
    }
});