//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from "objects/shared/DOMReady";
import { MenuSearch } from "objects/MenuSearch";

//==================================================================================================
//	DOM ready logic
//==================================================================================================
new DOMReady(function menuSearchInit() {

    // Select the navigation from the DOM
    const navigationContainer = document.querySelector(".main-navbar");

    // If navigation container is present
    if(navigationContainer) {
        // Look up nav search container
        const searchContainer = navigationContainer.querySelector("[data-menu-search]");
        // Look up search trigger
        const searchTrigger = navigationContainer.querySelector("[data-menu-search-trigger]");
        // IF nav search container is present
        if(searchContainer) {
            // Create a new navigation search instance
            const menuSearch = new MenuSearch(navigationContainer, searchContainer, searchTrigger);
        }
    }

});