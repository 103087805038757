import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";
import { keyAccessibility } from "helpers/keyAccessibility";

new DOMReady(() => {
    const fileUploadContainers = toArray(document.querySelectorAll(".FormFileUpload"));
    //Epi server team advise they can't change the HTML that is generated, need to create a styled file upload through script
    //This will be a div containing a label and a text input

    fileUploadContainers.forEach((fileUploadContainer) => {
        const fileUpload = fileUploadContainer.querySelector(".FormFileUpload__Input");

        //Create the new elements
        const label = document.createElement("label");
        label.setAttribute("role","button");
        label.setAttribute("tabindex","0");
        label.setAttribute("for",fileUpload.getAttribute("Id"));
        label.innerText="Select file";
        label.setAttribute("class","FormFileUpload__Label");

        const selectedFile = document.createElement("input");

        selectedFile.setAttribute("type", "text");
        selectedFile.setAttribute("readonly", true);
        selectedFile.setAttribute("tabindex", "-1");
        selectedFile.setAttribute("placeholder", fileUpload.getAttribute("title"));
        selectedFile.setAttribute("class", "FormFileUpload__SelectedFile");

        const container = document.createElement("div");
        container.setAttribute("class","FormFileUpload__Wrapper");

        container.appendChild(selectedFile);
        container.appendChild(label);

        //Insert before the the current file upload, so that the validation message maintains a correct position
        fileUploadContainer.insertBefore(container, fileUpload);

        //Add event listener to populate filename on change
        fileUpload.addEventListener("change", () => {
            const filename = fileUpload.value.split('\\').pop();
            selectedFile.value = filename;
        });

        //Add event listener for keyboard a11y on the label
        label.addEventListener("keydown", (event)=>{
            if(keyAccessibility.isSpaceOrEnter(event)){
                event.preventDefault();
                fileUpload.click();
              }
        });
    });
});