import { toArray } from "helpers/shared/toArray";

//==================================================================================================
//
//  In viewport
//
//==================================================================================================

const MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
const aosSelector = "[data-aos]";


//==================================================================================================
//	Private methods
//==================================================================================================
/**
 * Determines whether or not an element has come into view
 * @private
 * @function elementInView
 * @param {HtmlElement} element - element to check
 * @returns {Boolean} - true if the element is in the viewport
 * @memberof InViewport
 */
function elementInView(element) {
    return element.className.indexOf("aos-animate") !== -1;
}

/**
 * Create a MutationObserver to monitor when an element comes into view
 * @private
 * @function createObserver
 * @param {HtmlElement} element - element to check
 * @param {Function} callback - function call when element comes into view
 * @memberof InViewport
 */
function createObserver(element, callback) {
    const observer = new MutationObserver(function (mutations) {
        if (!mutations) {
            return;
        }

        if (elementInView(element)) {
            callback(element);
            observer.disconnect();
        }
    });

    //observer changes to attributes only, we just need to know when a class has been added to detect the relevant change
    observer.observe(element, { attributes: true });
}

/**
 * This calls the supplied function for each element with the attribute data-aos when it comes into view
 * This is because we're leveraging the functionality in AOS to determine when an element is in the viewport
 * rather than recreating that logic
 * Note that callback is only fired once at present, ie no multiple callbacks for elements going out and in of the viewport
 * @function InViewport
 * @param {Function} callback - will be called with the element that has come into view as a parameter.
 * @example
 * const callback=(element)=>{
*   const things = element.querySelectorAll(".something-of-interest")
*   if(things.length>0){
 *      things.forEach((thing)=>{thing.animate()});
 *  }
 * }
 *
 * InViewport(callback);
 */
export const InViewport = function (callback) {
    //Find all elements which aos will monitor on scroll
    var elementList = toArray(document.querySelectorAll(aosSelector));

    elementList.forEach((element) => {
        //They may be already in view and have been actioned by aos, so check if we can fire the callback immediately
        //Also do callback immediately if there is no MutationObserver in the browser
        if (elementInView(element) || !MutationObserver) {
            callback(element);
        }
        //Otherwise, we'll need to create a mutation observer to discover when aos considers them in view
        else {
            createObserver(element, callback);
        }
    });
}