//==================================================================================================
//
//	Toggle hidden
//
//==================================================================================================

if (HTMLElement.toggleHidden === undefined) {

    /**
     * Toggle the hidden state of an element, including aria-hidden attributes
     * @function external:HTMLElement#toggleHidden
     * @param {Boolean} hide - Hidden state (ie. true = hidden)
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".button");
     * element.toggleHidden("active");
     */
    HTMLElement.prototype.toggleHidden = function(hide) {
        const element = this;

        //  Note: We could use classList.toggle here, but we want to be explicit with our values.
        if (hide) {
            element.setAttribute("aria-hidden", true);
            if (element.classList.contains("hidden") === false) {
                element.classList.add("hidden");
            }
        }
        else {
            element.setAttribute("aria-hidden", false);
            if (element.classList.contains("hidden") === true) {
                element.classList.remove("hidden");
            }
        }

        return element;
    };

    /**
     * @function external:SVGElement#toggleHidden
     * @see external:HTMLElement#toggleHidden
     */
    SVGElement.prototype.toggleHidden = HTMLElement.prototype.toggleHidden;

}