//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { Resize } from "objects/shared/Resize";
import { interactiveState } from "helpers/interactiveState";

// Import animations
import { animateOpen } from "helpers/animations/megaMenu/animateOpen";
import { animateClose } from "helpers/animations/megaMenu/animateClose";
import { animateSubMenuIn } from "helpers/animations/megaMenu/animateSubMenuIn";
import { animateSubMenuOut } from "helpers/animations/megaMenu/animateSubMenuOut";

//==================================================================================================
//
//	Mega Menu
//
//==================================================================================================

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * This is used to provide navigation item tracking within the navigation to show the current mouseover target
 * @constructor
 * @param {HTMLElement} element - The DOM selected mega nav element.
 *
 * @property {HTMLElement} element - The dom selected mega menu element.
 * @property {HTMLElement} activeItem - The current active mega menu element (Primary Item)
 * @property {HTMLElement} searchElement - The search element displayed next to the mega menu
 * @property {Object} anime - The object bound anime object. This stores the animation during timeline plays.
 * @property {Boolean} activeTransition - Tracks if a transition is currently taking place [true = playing, false = clear].
 * @property {Object} activeSubMenu - Stores the active sub menu item
 * @property {Boolean} trayOpen - Status of the mega nav tray [true = mega nav tray open, false = no trays open]
 * @property {Boolean} resizeFired - Defines if a resize event has been fired for the nav. Used to prevent resize unless mobile to desktop change is detected.
 * @property {Array} topLevelItems - An array of all top level nav items.
 * @property {Array} trayItems - An array of defined trays and the corresponding columns.
 * @property {HTMLElement} trayItems.listItem - The primary nav item associated with the tray.
 * @property {HTMLElement} trayItems.anchorElement - The anchor element of the primary nav item.
 * @property {HTMLElement} trayItems.trayElement - The tray element.
 * @property {HTMLElement} trayItems.trayElementContainer - The tray container element.
 * @property {HTMLElement} trayItems.trayBackButton - The back button element for the tray.
 * @property {Object} trayItems.trayDetails - An object holding details about the current tray item.
 * @property {HTMLElement} trayItems.trayDetails.trayElement - The tray element.
 * @property {HTMLElement} trayItems.trayDetails.trayElementContainer - The tray container element.
 * @property {HTMLElement} trayItems.trayDetails.navTrayBackButton - The tray back button element.
 * @property {Object} trayItems.trayDetails.columns - Object to store the tray columns
 * @property {HTMLElement} trayItems.trayDetails.columns.infoColumn - The info column element.
 * @property {HTMLElement} trayItems.trayDetails.columns.navColumn - The nav column element.
 * @property {HTMLElement} trayItems.trayDetails.columns.promoColumn - The promo column element.
 * @property {Object} trayItems.trayDetails.subMenus - An Object to store the trays sub menus.
 * @property {HTMLElement} trayItems.trayDetails.subMenus.trigger - The sub menu trigger element.
 * @property {HTMLElement} trayItems.trayDetails.subMenus.element - The sub menu element.
 * @property {Boolean} trayItems.trayDetails.subMenus.extended - If the menu is extended (More than 10 items).
 * @property {Array} trayItems.trayDetails.subMenus.subNavColumns - An array of sub menu column elements.
 * @property {Boolean} hardReset - Defines if animations should be played over 0s - This is used when the requirement to quickly reset nav states is required.
 *
 * @example
 * // Look up the dom for mega menus
 * const megaMenus = toArray(document.querySelectorAll("[data-mega-menu]"));

 * // If the mega menu exists
 * if(megaMenus.length > 0) {
 *      // Loop through the elements
 *      megaMenus.forEach(function(currentMenu) {
 *
 *          // Create a new mega menu
 *          const megaMenu = new MegaMenu(currentMenu);
 *
 *      });
 * }
 */
export const MegaMenu = function(element) {
    // Cache instance
    const megaMenu = this;

    // Define properties
    megaMenu.element = element;
    megaMenu.activeItem = undefined;
    megaMenu.searchElement = document.querySelector("[data-menu-search-trigger]");
    megaMenu.anime = undefined;
    megaMenu.activeTransition = false;
    megaMenu.activeSubMenu = undefined;
    megaMenu.trayOpen = false;
    megaMenu.resizeFired = false;

    // Initiate mega menu
    megaMenu.init();

};

//==================================================================================================
//	Init
//==================================================================================================
/**
 * Initiates the Mega Menu
 * @memberOf MegaMenu
 */
MegaMenu.prototype.init = function() {
    // Cache instance
    const megaMenu = this;

    // Define items
    megaMenu.defineItems();

    // Attach Events
    megaMenu.attachEvents();

    // Set Heights
    megaMenu.setHeights();
};


//==================================================================================================
//	Define Items
//==================================================================================================
/**
 * Defines the Mega Menu Items
 * @memberOf MegaMenu
 */
MegaMenu.prototype.defineItems = function() {
    // Cache instance
    const megaMenu = this;

    // Get all top level items
    megaMenu.topLevelItems = toArray(megaMenu.element.querySelectorAll("[data-primary-nav-item]"));

    // Handle the top level links
    megaMenu.handleTopLevel();

    // Define the tray
    megaMenu.defineTrayLevel();

    // Attach tray events
    megaMenu.attachTrayEvents();

};

//==================================================================================================
//  Handle top level nav items
//==================================================================================================
/**
 * Handles top level nav items and sets relevant events
 * @memberOf MegaMenu
 */
MegaMenu.prototype.handleTopLevel = function() {
    // Cache instance
    const megaMenu = this;

    // Set property to store nav item data
    megaMenu.trayItems = [];

    // Loop through top level items
    megaMenu.topLevelItems.forEach(function(currentItem) {
        // Select the outer LI element
        const listItemElement = currentItem.parentNode;

        // Look for a nav tray
        const navTray = listItemElement.querySelector("[data-mega-menu-tray]");

        // If nav tray is found
        if(navTray !== null) {

            // Look for tray container
            const navTrayContainer = navTray.querySelector("[data-mega-menu-tray-container]");

            // Find back button on container
            const navTrayBackButton = navTrayContainer.querySelectorAll("[ data-mega-menu-tray-back-button]");

            // Form sub menu data array
            const itemData = {
                listItem: listItemElement,
                anchorElement: currentItem,
                trayElement: navTray,
                trayElementContainer: navTrayContainer,
                trayBackButton: navTrayBackButton
            };

            // Push data to property array
            megaMenu.trayItems.push(itemData);
        }
    });

};

//==================================================================================================
//  Define Tray Items
//==================================================================================================
/**
 * Handles tray items including sub menu items
 * @memberOf MegaMenu
 */
MegaMenu.prototype.defineTrayLevel = function() {
    // Cache instance
    const megaMenu = this;

    // Loop through each tray
    megaMenu.trayItems.forEach(function(currentTrayItem) {

        // Define Tray Details
        currentTrayItem.trayDetails = {
            trayElement: currentTrayItem.trayElement,
            trayElementContainer: currentTrayItem.trayElementContainer,
            navTrayBackButton: currentTrayItem.trayBackButton,
            columns: {
                infoColumn: currentTrayItem.trayElement.querySelector("[data-tray-info]"),
                navColumn: currentTrayItem.trayElement.querySelector("[data-tray-nav]"),
                promoColumn: currentTrayItem.trayElement.querySelector("[data-tray-promo]")
            }
        };

        // Look Up tray links
        const trayListItems = toArray(currentTrayItem.trayElement.querySelectorAll(".mega-menu-tray__nav-item"));

        // Create place holder for level 2 sub menus
        const subMenuTriggers = [];

        // Loop through each tray list item
        trayListItems.forEach(function(currentListItem) {

            // Lookup second nav level
            const secondLevel = currentListItem.querySelector("[data-nav-level-2]");

            // Define trigger
            const secondLeveltrigger = currentListItem.querySelector("a");

            // Placeholder for extended
            let extended = false;

            // Check if item has a sub menu
            if(secondLevel !== null) {

                // Check if extended
                if(secondLevel.getAttribute("data-extended") !== null && secondLevel.getAttribute("data-extended") !== undefined) {
                    // Set extended to true
                    extended = true;
                }

                // Define Columns
                const subNavColumns = toArray(secondLevel.querySelectorAll("[data-subnav-column]"));

                // Add level 2 element to tray items
               subMenuTriggers.push({trigger: secondLeveltrigger, element: secondLevel, extended: extended, subNavColumns: subNavColumns});
            }
        });

        // Check if sub menu's are present
        if(subMenuTriggers.length > 0) {
            // Add to current tray item
            currentTrayItem.trayDetails.subMenus = subMenuTriggers;
        }

    });

};

//==================================================================================================
//	Attach Tray events
//==================================================================================================
/**
 * Attaches relevant events to handle changes within the menu's tray items
 * @memberOf MegaMenu
*/
MegaMenu.prototype.attachTrayEvents = function() {
    // Cache instance
    const megaMenu = this;

    // Loop through tray items
    megaMenu.trayItems.forEach(function(currentTrayItem) {
        // If tray does not have sub menus
        if(!currentTrayItem.trayDetails.subMenus ||
            currentTrayItem.trayDetails.subMenus.length < 1) {
            // Return false and end execution
            return false;
        }

        // Set holder for tray details
        const trayDetails = currentTrayItem.trayDetails;

        // Loop through sub menu's
        trayDetails.subMenus.forEach(function(currentSubMenu) {

            // Attach Click event to trigger
            currentSubMenu.trigger.addEventListener("click", function handleClick(event) {
                // Prevent Default
                event.preventDefault();

                // Check if animation is currently firing
                if(megaMenu.activeTransition === true) {
                    // return false to fix multi-click issue
                    return false;
                }

                // If Item is currently the active item
                if(megaMenu.activeSubMenu === currentSubMenu) {
                    // Close the sub menu
                    megaMenu.closeSubLevel(trayDetails, currentSubMenu);
                }
                else {
                    // Check if there is a active item
                    if(megaMenu.activeSubMenu !== undefined) {
                        // Check if the menu being clicked is in the same tray
                        if(megaMenu.activeItem.trayElement !== megaMenu.activeSubMenu.tray.trayElement) {
                            // Set hard reset to true
                            megaMenu.hardReset = true;
                            // Close the sub menu
                            megaMenu.closeSubLevel();
                            // Open the new sub menu
                            megaMenu.openSubLevel(trayDetails, currentSubMenu);
                        }
                        else {
                            // Set hard reset to false
                            megaMenu.hardReset = false;
                            // Open the sub menu
                            megaMenu.openSubLevel(trayDetails, currentSubMenu);
                        }
                    }
                    else {
                        // Set hard reset to false
                        megaMenu.hardReset = false;
                        // Open the sub menu
                        megaMenu.openSubLevel(trayDetails, currentSubMenu);
                    }
                }

            });
        });

    });

    // Attach resize events
    new Resize(function() {
        // Reset the menu
        megaMenu.reset();
    });

};

//==================================================================================================
//	Set Heights
//==================================================================================================
/**
 * Sets the heights of mega menu lists to match each other
 * @memberOf MegaMenu
 */
MegaMenu.prototype.setHeights = function () {
    // Cache instance
    const megaMenu = this;


    // Perform document interactive event
    const waitForInteractive = new interactiveState();

    // Once the dom is interactive proceed
    waitForInteractive.then(() => {

        // Loop Through nav items
        megaMenu.trayItems.forEach(function (currentItem) {

            // Get height of element
            const heightOfTray = currentItem.trayElement.offsetHeight;

            // Store the height
            currentItem.trayOriginalSize = heightOfTray + 20;

        });
    });
};


//==================================================================================================
//	Open
//==================================================================================================
/**
 * Opens the Mega Menu
 * @memberOf MegaMenu
 */
MegaMenu.prototype.open = function() {
    // Cache instance
    const megaMenu = this;

    // Animate the menu open
    animateOpen(megaMenu);
};

//==================================================================================================
// Close
//==================================================================================================
/**
 * Closes the Mega Menu - Supports optional promise resolve, reject
 * @memberOf MegaMenu
 * @param {Function} resolve - The function to fire if resolved
 * @param {Function} reject - The function to fire if rejected
 */
MegaMenu.prototype.close = function(resolve, reject) {
    // Cache instance
    const megaMenu = this;

    //Check if active transition
    if(megaMenu.activeTransition !== true) {
        // Animate the menu closed
        animateClose(megaMenu, resolve, reject);
    }
    // If hardset is active
    else if(megaMenu.hardReset === true) {
        // Animate the menu closed
        animateClose(megaMenu, resolve, reject);
    }
};

//==================================================================================================
//	Open Level 2
//==================================================================================================
/**
 * Opens the Mega Menu sublevel
 * @memberOf MegaMenu
 * @param {Object} trayDetails - Passes in the menu tray details.
 * @param {Object} subMenu - The sub menu to be animated in.
 * @example
 * // Cache the instance
 * const megaMenu = this;
 *
 * // Cache instance
 * const megaMenu = this;
 *
 * // Loop through tray items
 * megaMenu.trayItems.forEach(function(currentTrayItem) {
 *
 * // Set holder for tray details
 * const trayDetails = currentTrayItem.trayDetails;
 *
 * // Loop through sub menu's
 * trayDetails.subMenus.forEach(function(currentSubMenu) {
 *
 *      // Attach Click event to trigger
 *      currentSubMenu.trigger.addEventListener("click", function handleClick(event) {
 *          // Prevent Default
 *          event.preventDefault();
 *
 *          // Open the sub menu
 *          openSubLevel(trayDetails, currentSubMenu);
 *      });
 * });
 */
MegaMenu.prototype.openSubLevel = function(trayDetails, subMenu) {
    // Cache instance
    const megaMenu = this;

    // Check if there is a mega menu has an active sub menu
    if(megaMenu.activeSubMenu !== undefined) {
        // check if same item
        if(megaMenu.activeSubMenu.menu === subMenu) {
            // Close the sub menu
            megaMenu.closeSubLevel();
        }
        // Close previous and open new one
        else {
            // Create a new promise
            const animationFinished = new Promise(function(resolve, reject) {
                // Close the sub menu
                megaMenu.closeSubLevel(resolve, reject);
            });

            // Handle resolved state
            animationFinished.then(function() {

                // Create a small delay to avoid flickering
                window.setTimeout(function() {
                    // Animate in sub menu
                    animateSubMenuIn(megaMenu, trayDetails, subMenu);
                }, 100);
            });
        }

    }
    else {
        // Animate in submenu
        animateSubMenuIn(megaMenu, trayDetails, subMenu);
    }
};

//==================================================================================================
//	Close Level 2
//==================================================================================================
/**
 * Closes the Mega Menu sublevel
 * @memberOf MegaMenu
 * @param {Function} resolve - The function to fire once resolved (Optional)
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Create a new promise
 * const closeMenu = new Promise((resolve, reject) => {
 *      // Animate Sub Menu Out
 *      closeSubLevel(resolve);
 * });
 *
 * // Once closed
 * closeMenu.then(() => {
 *      // Remove active item
 *      megaMenu.activeSubMenu = undefined;
 * });
 */
MegaMenu.prototype.closeSubLevel = function(resolve) {
    // Cache instance
    const megaMenu = this;

    // Animate Sub Menu Out
    animateSubMenuOut(megaMenu, megaMenu.activeSubMenu.tray, megaMenu.activeSubMenu.menu, resolve);

    // Remove active item
    megaMenu.activeSubMenu = undefined;
};

//==================================================================================================
// Attach Events
//==================================================================================================
/**
 * Attaches Events to the Mega Menu
 * @memberOf MegaMenu
 */
MegaMenu.prototype.attachEvents = function() {
    // Cache instance
    const megaMenu = this;

    // Attach events to primary nav items
    megaMenu.topLevelItems.forEach(function(currentItem) {
        // Add focus event listener to close previous active item
        currentItem.addEventListener("focus", function() {

            // Set flag
            let trayCheck = false;

            // Loop through tray items
            megaMenu.trayItems.forEach((currentTrayItem) => {
                if(currentTrayItem.anchorElement === currentItem) {
                    trayCheck = true;
                }
            });

            // If the item has no tray
            if(!trayCheck) {
                megaMenu.close();
            }
        });
    });

    // Handle shift tab from first item (Tab reverse)
    megaMenu.topLevelItems[0].addEventListener("keydown", function(event) {
        // If shift + tab is pressed
        if(event.shiftKey === true && event.key === "Tab") {
            // Close the active menu item
            megaMenu.close();
        }
    });

    // Handle tab to search trigger
    megaMenu.searchElement.addEventListener("focus", function() {
        // If there is an active item
        if(megaMenu.activeItem !== undefined) {
            // Close the existing menu
            megaMenu.close();
        }
    });

    // Add Brush Over Close
    megaMenu.element.addEventListener("mouseleave", () => {
        // Close the menu
        megaMenu.close();
    });


    // Attach events for tray items
    megaMenu.trayItems.forEach(function (currentItem) {

        // Click event
        currentItem.listItem.addEventListener("click", function (event) {
            // Check for touch device
            const touch = matchMedia('(hover: none)').matches;

            let tray = currentItem.listItem.getElementsByClassName('mega-menu-tray')[0];

            if (touch && tray.style.visibility == 'hidden') {
                // Prevent Default
                event.preventDefault();

                // Set the previous active element
                megaMenu.previousActive = megaMenu.activeItem;
            }
        });

        // Mouse enter event
        currentItem.listItem.addEventListener("mouseenter", function(event) {

            // Clear the close timeout
            clearTimeout(megaMenu.closeTimeout);

            // Set timeout to prevent open when user brushes menu
            megaMenu.openTimeout = setTimeout(function() {
                // Set placeholder flag
                let stayOpen = false;

                // Loop through tray items
                megaMenu.trayItems.forEach(function(currentItem) {
                    // If ther event target is the current item
                    if(event.target === currentItem.listItem) {
                        // set stay open flag to true
                        stayOpen = true;
                    }
                });


                // If the mega menu has an active item
                if(megaMenu.activeItem !== undefined && stayOpen !== true) {
                    // If the mega menu active item is not the current item
                    if(megaMenu.activeItem !== currentItem) {
                        // Close the menu then open the new one
                        const openMenu = new Promise(function(resolve, reject) {
                            // close the menu
                            megaMenu.close(resolve);
                        });

                        // Once promise returns
                        openMenu.then(function() {
                            // Set the new active item
                            megaMenu.activeItem = currentItem;
                            // Open the menu
                            megaMenu.open();
                        });
                    }
                }
                else {

                    // Clear previous timeout
                    clearTimeout(megaMenu.menuChangeTimeout);

                    // If the previous active is the current element
                    if(megaMenu.previousActive === currentItem) {
                        // Return false
                        return false;
                    }

                    // Set the previous active element
                    megaMenu.previousActive = megaMenu.activeItem;

                    // Set timeout to avoid flicker effect
                    megaMenu.menuChangeTimeout = setTimeout(function() {
                        // Check if there is an active item
                        if(megaMenu.activeItem !== undefined) {
                            // Check if the new item being toggled is the same as the active
                            if(megaMenu.activeItem.anchorElement === currentItem.anchorElement) {
                                // Prevent Execution
                                return false;
                            }
                        }

                        // Set the active item
                        megaMenu.activeItem = currentItem;

                        // Open the menu
                        megaMenu.open();
                    }, 250);
                    // Note this may need added to to ensure fast click doesn't break

                }
            }, 120);

        });

        // Mouse Leave event
        currentItem.listItem.addEventListener("mouseleave", function(event) {

            // Clear the existing timeout
            clearTimeout(megaMenu.openTimeout);

            megaMenu.closeTimeout = setTimeout(() => {


                // If the mega menu has an active item
                if(megaMenu.activeItem !== undefined) {
                    // Set placeholder flag
                    let stayOpen = false;

                    // Loop through tray items
                    megaMenu.trayItems.forEach(function(currentItem) {
                        // If event related target
                        if(event.relatedTarget !== null) {
                            // If the to item is another menu item
                            if(event.relatedTarget  === currentItem.listItem || event.relatedTarget.parentNode === currentItem.listItem) {
                                // Set flag to true
                                stayOpen = true;
                            }
                        }
                        else {
                            // Set the flag to true
                            stayOpen = true;
                        }
                    });

                    // Check if menu should stay open
                    if(stayOpen === false) {
                        // Ignore the safety net for animation
                        megaMenu.hardReset = true;
                        // Close the menu
                        megaMenu.close();
                    }

                }
            }, 200);

        });

        // Focus Event
        currentItem.anchorElement.addEventListener("focus", function() {
            // If the mega menu has an active item
            if(megaMenu.activeItem !== undefined) {
                // If the mega menu active item is not the current item
                if(megaMenu.activeItem !== currentItem) {
                    // Set the active item
                    megaMenu.activeItem = currentItem;
                    // Open the menu
                    megaMenu.open();
                }
            }
            else {
                // Set the active item
                megaMenu.activeItem = currentItem;
                // Open the menu
                megaMenu.open();
            }
        });

    });

    // Attach resize event
    new Resize(function () {
        // Fire handle resize
        megaMenu.handleResize();
    });
};

//==================================================================================================
// Handle Resize
//==================================================================================================
/**
 * Handles the resize
 * @memberOf MegaMenu
 */
MegaMenu.prototype.handleResize = function () {
    // Cache Instance
    const megaMenu = this;

    // Set timeout to debounce
    window.setTimeout(function () {
        // If the resize event has not been fired
        if (megaMenu.resizeFired === false) {
            // If the window inner width is more than 1140
            if (window.innerWidth >= 1140) {
                // Fire set heights
                megaMenu.setHeights();
                // Set fired resize to true
                megaMenu.resizeFired = true;
            }
        }
    }, 500);
};

//==================================================================================================
// Reset Menu
//==================================================================================================
/**
 * Resets the Mega Menu
 * @memberOf MegaMenu
 */
MegaMenu.prototype.reset = function() {
    // Cache instance
    const megaMenu = this;

    // Enable hard reset
    megaMenu.hardReset = true;

    // If there is a sub menu active
    if(megaMenu.activeSubMenu) {
         // Close the sub menu
         megaMenu.closeSubLevel();
    }

    // If there is an active menu item
    if(megaMenu.activeItem) {
        // Close the menu
        megaMenu.close();
    }

    // Disable hard reset
    megaMenu.hardReset = false;

};