import { CountrySelector } from "objects/CountrySelector";
import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";

new DOMReady(()=>{
    const elements = toArray(document.querySelectorAll(".country-selector"));

    elements.forEach((element)=>{
        CountrySelector(element);
    });
});