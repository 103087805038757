//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from 'objects/shared/DOMReady';

//==================================================================================================
//	A11Y Initiation to only show accessible attributes when tabbing
//==================================================================================================
/**
 * Function used to enable / disable outlines on elements based on accessibility
 *
 * @function
 * @ignore
 * @example
 * // User Tabs
 * <body data-a11y>
 *
 * // User Clicks
 * <body>
 */
new DOMReady(() => {

    // Set flag for a11y status
    let a11yStatus = false;

    // Add event to detect key press
    document.addEventListener("keydown", (event) => {
        // Detect if the user action is tab or shift tab
        if(event.key === "Tab" && a11yStatus === false) {
            // Bind attribute to the body
            document.body.setAttribute("data-a11y", "");

            // Set status to active
            a11yStatus = true;
        }
    });

    // Add event to detect click
    document.addEventListener("click", () => {
        // Check if a11y status is active
        if(a11yStatus === true) {
            // Remove attribute from body
            document.body.removeAttribute("data-a11y");

            // Set status to false
            a11yStatus = false;
        }
    });
});