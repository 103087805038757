//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from "objects/shared/DOMReady";
import { Resize } from "objects/shared/Resize";
import Siema from "siema";

import { interactiveState } from "helpers/interactiveState";
import { pagination } from "helpers/carousel/pagination";
import { touchEvents } from "helpers/carousel/touchEvents";
import { arrowEvents } from "helpers/carousel/arrowEvents";
import { SlideAccessiblity } from "objects/SlideAccessiblity";

//==================================================================================================
//  Private Functions
//==================================================================================================
// Create New Resize Event
function carouselResize(instance) {
	// Cache the instance
	const carousel = instance;

	// If the window is desktop
	if (window.innerWidth >= 960 && carousel.activeViewport !== "desktop") {
		// Set the slider to slide 3 at a time
		carousel.numToSlide = 3;
		// Set the active viewport to desktop
		carousel.activeViewport = "desktop";
		// Set carousel to position 0
		carousel.goTo(0);
	} else if (
		window.innerWidth > 600 &&
		window.innerWidth < 960 &&
		carousel.activeViewport !== "tablet"
	) {
		// Set the slider to slide 2 at a time
		carousel.numToSlide = 2;
		// Set the active viewport to tablet
		carousel.activeViewport = "tablet";
		// Set carousel to position 0
		carousel.goTo(0);
	} else if (window.innerWidth < 600 && carousel.activeViewport !== "mobile") {
		// Set the slider to slide 1 at a time
		carousel.numToSlide = 1;
		// Set the active viewport to mobile
		carousel.activeViewport = "mobile";
		// Set carousel to position 0
		carousel.goTo(0);
	}
	//Disable tabbing on all but the current slide
	carousel.slideAccessiblity.restrictTabbingToSlides(
		carousel.currentSlide,
		carousel.numToSlide
	);
}

function toggleArrows(arrows, instance) {
	// Cache the instance
	const carousel = instance;

	if (carousel.activeViewport == "mobile" || !arrows.prev || !arrows.next) {
		// If it's mobile or the arrows are missing we should just return
		return false;
	}

	let page = Math.ceil(carousel.currentSlide / carousel.numToSlide) + 1;
	let pages = Math.ceil(carousel.numSlides / carousel.numToSlide);

	if (pages == 1) {
		// If there's only one page
		arrows.prev.classList.add("hidden");
		arrows.next.classList.add("hidden");
	} else if (page == 1 && pages > 1) {
		// If it's page one and there are more pages...
		arrows.prev.classList.add("hidden");
		arrows.next.classList.remove("hidden");
	} else if (page > 1 && page < pages) {
		// If it's not page one and there are more pages to come...
		arrows.prev.classList.remove("hidden");
		arrows.next.classList.remove("hidden");
	} else if (page > 1 && page == pages) {
		// If it's not page one and there are no more pages to come...
		arrows.prev.classList.remove("hidden");
		arrows.next.classList.add("hidden");
	}
}

//==================================================================================================
//
//  Three Block Carousel
//
//==================================================================================================

new DOMReady(function initThreeBlockCarousel() {
	// / Query Select Carousel conatiner
	const containers = toArray(
		document.querySelectorAll("[data-three-block-carousel-wrapper]")
	);

	// Check container exists
	if (containers.length > 0) {
		// Loop through container
		containers.forEach(function initCarousel(currentItem) {
			// Find the carousel
			const carouselElement = currentItem.querySelector(
				"[data-three-block-carousel]"
			);

			// Find the carousel arrows
			const arrows = {
				prev: currentItem.querySelector("[data-carousel-prev]"),
				next: currentItem.querySelector("[data-carousel-next]")
			};

			// Find the pagination component
			const paginationContainer = currentItem.querySelector(
				"[data-pagination]"
			);

			// Detect the slides
			const slides = currentItem.querySelectorAll("[data-slide]");
			// Instantiate slide tab manager
			const slideAccessiblity = new SlideAccessiblity(
				carouselElement,
				"[data-slide]"
			);

			// Create a new carousel
			const carousel = new Siema({
				selector: carouselElement,
				duration: 200,
				easing: "ease-out",
				perPage: {
					960: 3,
					600: 2,
					0: 1
				},
				startIndex: 0,
				draggable: false,
				threshold: 20,
				loop: false,
				onChange: () => {
					// Loop through each carousel dot
					carousel.carouselDots.forEach(currentPaginationItem => {
						// Remove the active attribute
						currentPaginationItem.removeAttribute("data-active");
					});

					// If the carousel is more than the max (returns -1)
					if (carousel.currentSlide < 0) {
						// Set the last item as active
						carousel.carouselDots[
							carousel.carouselDots.length - 1
						].setAttribute("data-active", "true");
					} else {
						// If the num to slide is 1
						if (carousel.numToSlide === 1) {
							// Set the current slide as active
							carousel.carouselDots[carousel.currentSlide].setAttribute(
								"data-active",
								"true"
							);
						} else {
							// Calculate which pagination item to pic
							const desiredDot = Math.ceil(
								carousel.currentSlide / carousel.numToSlide
							);
							// Set the active item
							carousel.carouselDots[desiredDot].setAttribute(
								"data-active",
								"true"
							);
						}
					}

					toggleArrows(arrows, carousel);

					//Disable tabbing on all but the current slide
					slideAccessiblity.restrictTabbingToSlides(
						carousel.currentSlide,
						carousel.numToSlide
					);
				}
			});

			carousel.numSlides = slides.length;

			// Add custom property for number to slide
			carousel.numToSlide = 3;

			// Add custom property on carousel for resize - the resize event will take care of initialisation
			carousel.slideAccessiblity = slideAccessiblity;

			// Add Touch Events
			touchEvents(carouselElement, carousel);

			// Add arrow events
			arrowEvents(arrows, carousel);
			toggleArrows(arrows, carousel);

			// Fire interactive state wait
			const interactive = new interactiveState();

			// On interactive fire
			interactive.then(() => {
				// Fire resize
				carouselResize(carousel);

				// Create the carousel dots
				pagination(paginationContainer, slides, carousel);

				// Call resize handle
				// Fixes issue with uglify firing function too early
				carousel.resizeHandler();
				slideAccessiblity.restrictTabbingToSlides(
					carousel.currentSlide,
					carousel.numToSlide
				);
			});

			let resizeTimeout;
			// Create a new resize handler
			new Resize(() => {
				clearTimeout(resizeTimeout);

				resizeTimeout = setTimeout(() => {
					// Fire resize
					carouselResize(carousel);
					// Update the dots
					pagination(paginationContainer, slides, carousel);
				}, 50);
			});
		});
	}
});
