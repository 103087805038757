import { toArray } from "helpers/shared/toArray";

/**
 * Used to insure the hero banners are a consistent height
 * @function
 * @param {Array} slides - The array of slide elements to be matched in height
 * @ignore
 * @example
 * // Match the slide heights
 * matchHeights(slides);
 */
export const matchSlideHeights = (slides) => {
    slides = toArray(slides);
    // Placeholder to store largest height
    let largestHeight = 0;

    // Clear the height property
    slides.forEach((currentSlide) => {
        currentSlide.style.height = "";
    });

    // Loop through the slides
    slides.forEach((currentSlide) => {
        // Get the height of thre slide
        const slideHeight = currentSlide.offsetHeight;
        // Check if the height is bigger than the largest
        if(slideHeight > largestHeight) {
            largestHeight = slideHeight;
        }
    });

    // Loop through the slides
    slides.forEach((currentSlide) => {
        // Set the height of the slides
        currentSlide.style.height = largestHeight + "px";
    });
};