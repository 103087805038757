//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Sub Menu Closed
//  This is the function that is called to animate the sub menu closed
//
//==================================================================================================

/**
 * Function used to close the sub menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Close the sub menu
 * closeSubMenu(megaMenu);
 */
export const closeSubMenu = (instance) => {
    // Cache instance
    const megaMenu = instance;

    const menuItem = megaMenu.activeItem;
    const subMenu = megaMenu.activeSubMenu;

     // Define tray items
     const trayItems = [];

     // Filter the array of items so does not contain the active one
     menuItem.tray.items.filter(function(currentTrayItem) {
         if(currentTrayItem.element !== subMenu.element) {
             trayItems.push(currentTrayItem.element);
         }
     });

     // Create timeline
     const timeline = anime.timeline({
         autoplay: false
     });

     // Animate Sub Menu Out
     timeline.add({
         targets: subMenu.subMenu.container,
         translateX: "100%",
         opacity: 0,
         duration: megaMenu.hardReset ? 0 : 300,
         easing: "linear",
         complete: function() {
             // Set the container to hidden
             subMenu.subMenu.container.style.visibility = "hidden";
         }
     });

     // Reposition Tray trigger
     timeline.add({
         targets: subMenu.element,
         translateY: 0,
         duration: megaMenu.hardReset ? 0 : 200,
         easing: "linear",
         complete: function() {
             // Remove the active state
             subMenu.element.removeAttribute("data-active", "");
         }
     });

     // Animate in tray items
     timeline.add({
         begin: function() {
             // Loop through tray items
             trayItems.forEach(function(currentTrayItem) {
                 // Set visibility to visible
                 currentTrayItem.style.visibility = "visible";
             });
         },
         targets: trayItems,
         translateX: 0,
         duration: megaMenu.hardReset ? 0 : 300,
         easing: "linear",
         offset: "-=100",
     });

     // Reset Tray Trigger
     timeline.add({
         targets: subMenu.trigger,
         paddingLeft: 0,
         duration: megaMenu.hardReset ? 0 : 200,
         easing: "linear"
     });

     // Restore the size of the list
     timeline.add({
         targets: menuItem.tray.container,
         height: `${menuItem.tray.containerSize}px`,
         duration: megaMenu.hardReset ? 0 : 200,
         easing: "linear",
         complete: function() {
             // Clear the active sub menu
             megaMenu.activeSubMenu = undefined;
            // Reset the harReset value
             megaMenu.hardReset = false;
         }
     });

     // Play the timeline
     timeline.play();

 };