//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Tray Closed
//  This is the function that is called to animate the tray closed
//
//==================================================================================================

/**
 * Function used to close the tray menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Close the tray menu
 * closeTrayMenu(megaMenu);
 */
export const closeTrayMenu = (instance) => {
    // Cache instance
    const megaMenu = instance;

    // Cache active item
    const activeItem = megaMenu.activeItem;

    // Cache active nav item
    const activeNavItem = megaMenu.activeItem.primaryNavItem;


    // Create new timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Form array of all non active elements to be removed
    // Define menu items
    const menuItems = [];

    // Filter the array of items so does not contain the active one
    megaMenu.menuItems.filter(function(currentMenuItem) {
        if(currentMenuItem.primaryNavItem !== activeNavItem) {
            menuItems.push(currentMenuItem.primaryNavItem.element);
        }
    });

    // Animate out Tray items
    timeline.add({
        targets: activeItem.tray.element,
        translateX: "100%",
        duration: 300,
        easing: "linear",
        complete: function() {
            // Hide the tray
            activeItem.tray.element.style.visibility = "hidden";
            activeItem.tray.element.style.maxHeight = "0";
            activeItem.tray.element.style.display = "none";
        }
    });


    // Animate the trigger to the top of the page
    timeline.add({
        begin: function() {

            // Loop through menu items
            menuItems.forEach(function(currentItem) {
                // Display block the non active items
                currentItem.style.display = "inline-block";
            });

            // Animate the active item to the offset
            anime({
                targets: activeNavItem.element,
                translateY: `-${activeItem.primaryNavItem.offset}px`,
                duration: 0
            });
        },
        duration: 100,
        opacity: 1,
        complete: function() {
            // Remove the active state
            activeNavItem.element.removeAttribute("data-active");
            // Animate the nav back to Y 0
            anime({
                targets: activeNavItem.element,
                translateY: "0px",
                duration: 300,
                easing: "linear"
            });
        }
    });

    // Animate Out Remaining Items
    timeline.add({
        targets: menuItems,
        translateX: "0",
        duration: 300,
        easing: "linear",
        offset: "+=300"
    });


    // Animate trigger
    timeline.add({
        targets: activeNavItem.element,
        paddingLeft: "10px",
        duration: 200,
        easing: "linear",
        complete: function() {
            // Clear the active item
            megaMenu.activeItem = undefined;
        }
    });

    // Play the timeline
    timeline.play();

};