//==================================================================================================
//
//	Manually trigger a DOM event on the element
//
//==================================================================================================

if (HTMLElement.trigger === undefined) {

    /**
     * Trigger a DOM event to fire against a particular element
     * @function external:HTMLElement#trigger
     * @param {String} type - Event type to be triggered
     * @returns {HTMLElement}
     * @example
     * let element = document.querySelector(".button");
     * element.removeClass("active");
     */
    HTMLElement.prototype.trigger = function(type) {
        const element = this;
        let event;

        if ("createEvent" in document) {
            event = document.createEvent("HTMLEvents");
            event.initEvent(type, false, true);
            element.dispatchEvent(event);
        }
        else {
            element.fireEvent(type);
        }

        return element;
    };

    /**
     * @function external:SVGElement#trigger
     * @see external:HTMLElement#trigger
     */
    HTMLElement.prototype.trigger = SVGElement.prototype.trigger;

}