import { toArray } from "helpers/shared/toArray";

const originalTabAttribute = "data-original-tab-index";
const originalFocusAttribute = "data-original-focusable";
const tabindex = "tabindex";
//svg in IE11 can always be tabbed on to unless we set focusable false
const focusable = "focusable";

function stripTabIndex(elements){
    const elementArray = toArray(elements);

    elementArray.forEach((element) => {
        //check if the element has previously been processed so that we don't lose the original value of tabindex
        if(element.getAttribute && element.getAttribute(originalTabAttribute) === null)
        {
            setAttributeOff(element, tabindex, originalTabAttribute, "-1");
            setAttributeOff(element, focusable, originalFocusAttribute, false);
        }
        stripTabIndex(element.childNodes);
    });
}

function setAttributeOff(element, attributeName, attributeStore, offValue){
    //Get current value,  convert null to an empty string
    const currentValue = element.getAttribute(attributeName) || "";

    //Store the current value in a data attribute on the element
    element.setAttribute(attributeStore, currentValue);

    //Set the attribute to the off state
    element.setAttribute(attributeName, offValue);
}

function restoreTabIndex(elements){
    const elementArray = toArray(elements);

    elementArray.forEach((element) => {
        if(element.getAttribute && element.getAttribute(originalTabAttribute) !== null)
        {
            setAttributeOn(element, tabindex, originalTabAttribute);
            setAttributeOn(element, focusable, originalFocusAttribute);
        }
        restoreTabIndex(element.childNodes);
    });
}

function setAttributeOn(element, attributeName, attributeStore) {
    const originalValue = element.getAttribute(attributeStore);
    element.removeAttribute(attributeStore);
    if(originalValue){
        element.setAttribute(attributeName, originalValue);
    }
    else {
        element.removeAttribute(attributeName);
    }
}

// TODO Good comments
// Initialise this before siema so it can count the slides without endless clones
export const SlideAccessiblity = function (container, selector){
    this.container = container;
    this.selector = selector;
    this.originalSlideCount = container.querySelectorAll(selector).length;
}

//Call this every time the browser is resized or the carousel index is changed
SlideAccessiblity.prototype.restrictTabbingToSlides = function (slidePosition, numberOfSlides) {
    const allSlides = toArray(this.container.querySelectorAll(this.selector));

    //Siema will have created clones of first and last for endless if loop = true, need to account for this
    const offSet = (allSlides.length - this.originalSlideCount) / 2;

    slidePosition += offSet;

    const slides = allSlides.slice(slidePosition, slidePosition + numberOfSlides);

    stripTabIndex(allSlides);
    restoreTabIndex(slides);
}

SlideAccessiblity.prototype.enableTabbingOnAllSlides = function () {
    restoreTabIndex (this.container.querySelectorAll(this.selector));
}