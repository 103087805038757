//==================================================================================================
//	Dependencies
//==================================================================================================
import { DOMReady } from 'objects/shared/DOMReady';
import { toArray } from "helpers/shared/toArray";
import { LoginOverlay } from "objects/LoginOverlay";

//==================================================================================================
//	Login Overlay
//==================================================================================================
/**
 * Temporary open/close button functionality to hide/show a login panel wrapped in an overlay.
 * @function initLoginOverlay
 * @ignore
 */
new DOMReady(function initLoginOverlay() {

    // Check for login the overlay element
    const loginOverlayElement = document.querySelector("[data-login-overlay]");

    // If element is present
    if(loginOverlayElement) {

        // Lookup triggers
        const triggerElements = toArray(document.querySelectorAll("[data-login-trigger]"));

        // Look up close button
        const loginOverlayCloseBtn = loginOverlayElement.querySelector("[data-login-overlay-close]");

        // If elements exist
        if(triggerElements.length > 0) {

            // Create new login overlay
            const loginOverlay = new LoginOverlay(loginOverlayElement, triggerElements, loginOverlayCloseBtn);

        }
    }
});