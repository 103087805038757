/*jslint es6 browser*/
//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from 'animejs';

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * Animates the width property of an element and calls back when the animation is complete
 * @constructor
 * @param {HTMLElement} animationTarget - The element to be animated
 * @param {Function} onComplete - Callback fired when the animation completes
 * @param {Number} duration - Duration, in milliseconds, of the animation
 * @example
 * const animatedElement = document.querySelector(".progress-bar");
 * const onComplete = () => { console.log("Animation complete") };
 * const duration = 5000;
 * const progressBar = new AnimatedProgressBar(animatedElement, onComplete, duration);
 * progressBar.start();
 */
export const AnimatedProgressBar = function (animationTarget, onComplete, duration){
    const animatedProgressBar = this;

    animatedProgressBar.animation = anime({
        begin: function() {
            animationTarget.style.width = "0";
        },
        targets:animationTarget,
        autoplay:false,
        duration:duration,
        loop:false,
        width:["0%", "100%"],
        easing:"linear",
        complete: onComplete
    });
};

//==================================================================================================
//  Stops the animation
//==================================================================================================
/**
 * @memberOf AnimatedProgressBar
 */
AnimatedProgressBar.prototype.stop = function (){
    this.animation.reset();
    this.animation.pause();
};

//==================================================================================================
//  Starts the animation
//==================================================================================================
/**
 * @memberOf AnimatedProgressBar
 */
AnimatedProgressBar.prototype.start = function (){
    this.animation.play();
};