//==================================================================================================
//
//	Diagnostic Tool
//
//==================================================================================================
import { toArray } from "helpers/shared/toArray";

//==================================================================================================
//	Constructor
//==================================================================================================
/**
 * The object provides functionality to the diagnostic tool.
//  * @constructor
//  * @param {HTMLElement} container - Container element of the diagnostic tool
 */

export const DiagnosticTool = function(container) {
    const diagnosticTool = this;

    //  Elements
    diagnosticTool.container = container;

    diagnosticTool.init();
};

//==================================================================================================
//	Init
//==================================================================================================
// /**
//  * Initialise the diagnosticTool instance
//  */
DiagnosticTool.prototype.init = function() {

    // cache instance
    const diagnosticTool = this;

    // define items
    diagnosticTool.views = toArray(diagnosticTool.container.querySelectorAll('[data-view'));

    // define button wrappers 
    diagnosticTool.buttonWrappers = toArray(diagnosticTool.container.querySelectorAll('.axa-diagnostic-tool__selector-btn-wrapper'));
    
    // get all buttons with icons
    diagnosticTool.iconButtons = toArray(diagnosticTool.container.querySelectorAll('.axa-diagnostic-tool__selector-button--icon'));

    // map out selected / unselected image objects
    diagnosticTool.iconArray = diagnosticTool.mapIconButtons();

    // attach events
    diagnosticTool.attachEvents();
};

//==================================================================================================
//	Attach Events
//==================================================================================================
// /**
//  * Define the question-sets and views
//  */  
DiagnosticTool.prototype.attachEvents = function() {

    // cache instance
    const diagnosticTool = this;

    diagnosticTool.buttonWrappers.forEach(function(el){
        el.addEventListener('click', function(e){

            // has user clicked on a button icon
            const buttonIconClicked = e.target.tagName === 'IMG' && e.target.parentNode.classList.contains('axa-diagnostic-tool__selector-button');

            // if button or icon clicked
            if(e.target.classList.contains('axa-diagnostic-tool__selector-button') || buttonIconClicked){

                // call handleButtonClick with button element
               diagnosticTool.handleButtonClick(this, buttonIconClicked ? e.target.parentNode : e.target)
            }
        });
    });
    
    diagnosticTool.iconArray.forEach(function(button){

        // show secondary image on hover
        button.el.addEventListener('mouseenter', function(){
            diagnosticTool.setImage(button, 'secondaryImage');
        })

        // show primary image when hover ends
        button.el.addEventListener('mouseleave', function(){
            diagnosticTool.setImage(button, 'primaryImage');
        })
    })
    
};

//==================================================================================================
//	HandleButtonClick
//==================================================================================================
DiagnosticTool.prototype.handleButtonClick = function(parent, clickedButton) {

    // cache instance
    const diagnosticTool = this;

    // define selected class
    const selectedClass = 'axa-diagnostic-tool__selector-button--selected'

    // get all buttons from parent btn-wrapper element
    let childButtons = toArray(parent.querySelectorAll('.axa-diagnostic-tool__selector-button'));

    // identify any button previously selected
    const previouslySelectedButton = parent.querySelector('.' + selectedClass);

    // if the user has changed their selection
    if(previouslySelectedButton !== clickedButton){

        // remove selected class for all buttons
        childButtons.forEach(function(btn){
            if(btn.classList.contains(selectedClass)){
                btn.classList.remove(selectedClass);
            }
        });

        // remove clickedButton from the list of child buttons
        childButtons.splice(childButtons.indexOf(clickedButton), 1);
        
        // find relevant view by passing in clickedButton and list of unselected sibling buttons
        var selection = diagnosticTool.getSelectedView(clickedButton, childButtons);
        
        // check whether clicked button image has secondary image to display
        var clickedImageObject = diagnosticTool.getImageObject(clickedButton)
        
        // if button has secondary image - display it
        if(clickedImageObject){
            diagnosticTool.setImage(clickedImageObject, 'secondaryImage');
        }

        // add selected class to button
        clickedButton.classList.add(selectedClass);
        
         // show selected view
        diagnosticTool.showView(selection.selected);
        
        // hide all unselected views and children
        diagnosticTool.hideViews(selection.unselected);

        if(previouslySelectedButton) {
            var previousImageObject = diagnosticTool.getImageObject(previouslySelectedButton);
    
            if(previousImageObject){
                diagnosticTool.setImage(previousImageObject, 'primaryImage');
            }
        }

    }
}


//==================================================================================================
//	Show View
//==================================================================================================
// /**
//  * Displays the relevant view based on the user's selection
//  * @param {HTMLElement} view - The view to be dispolayed as per the user's decision.
//  */
DiagnosticTool.prototype.showView = function(view) {

    // cache instance
    const diagnosticTool = this;

    // remove the 'hide' class from view
    if(view.classList.contains('hide')){
        view.classList.remove('hide')
    }

    view.setAttribute('aria-hidden', false);

    diagnosticTool.setFocus(view)

};

//==================================================================================================
//	Get selection view object
//==================================================================================================
// /**
//  * Returns an object containing selected and unselected view elements based on user's decision
//  * @param {HTMLElement} question - The question containing the option selected by the user
//  */
DiagnosticTool.prototype.getSelectedView = function(selectedButton, buttonArray) {
    
    // cache instance
    const diagnosticTool = this;

    // set empty result object
    const result = {};
    result.unselected = [];

    // get selected view from array based on data-view attribute
    result.selected = diagnosticTool.views.find(function(view){
        return view.dataset.view === selectedButton.dataset.viewTrigger;
    })

    // for each unselected input - get corresponding view and add to result array
    buttonArray.forEach(function(button){
        var matchingView = diagnosticTool.views.find(function(view){
            return view.dataset.view === button.dataset.viewTrigger
        });
        if(matchingView){
            result.unselected.push(matchingView);
        }
    })   

    return result;
};

//==================================================================================================
//	Hide Views
//==================================================================================================
// /**
//  * Call hide view and if an element has children, call the same function passing the children as params
//  * @param {Array} unselectedViews - an array of view HTMLElements which were unselected by the user
//  */
DiagnosticTool.prototype.hideViews = function(unselectedViews) {
    
    // cache instance
    const diagnosticTool = this;

    // for each unselected view
    unselectedViews.forEach(function(view){

        // hide the view
        diagnosticTool.hideView(view);

        // check whether the view contains sub-views as children
        let childrenViews = [].slice.call(view.querySelectorAll('[data-view]'));
        if(childrenViews.length > 0){
            
            // if there are children, call hideView passing those children as params
            childrenViews.forEach(function(childView){
                diagnosticTool.hideView(childView)
            })
        }
    })
};

//==================================================================================================
//	Hide View
//==================================================================================================
// /**
//  * Add the 'hide' class to a view
//  * @param {HTMLElement} view - the view to be hidden from the user
//  */
DiagnosticTool.prototype.hideView = function(view) {
    
    // cache instance
    const diagnosticTool = this;
    const selectedClass = 'axa-diagnostic-tool__selector-button--selected';

    const selectedButton = view.querySelector('.axa-diagnostic-tool__selector-button--selected');

    if(selectedButton){
        selectedButton.classList.remove(selectedClass);
    }

    var imageObject = diagnosticTool.getImageObject(selectedButton);
    
    if(imageObject){
        diagnosticTool.setImage(imageObject, 'primaryImage');
    }

    // add 'hide' class if not already present
    if(!view.classList.contains('hide')){
        view.classList.add('hide')
    }

    view.setAttribute('aria-hidden', true);

    view.removeAttribute('tabindex');
};

//==================================================================================================
//	Set focus
//==================================================================================================

DiagnosticTool.prototype.setFocus = function(view) {
    
    // cache instance
    const diagnosticTool = this;
    view.setAttribute('tabindex', -1);

    view.focus();
};

//==================================================================================================
//	Set Image
//==================================================================================================

DiagnosticTool.prototype.setImage = function(button, newImage) {
    
    // cache instance
    const diagnosticTool = this;
    
    if(!button.el.classList.contains('axa-diagnostic-tool__selector-button--selected')){
        const oldImage = button.el.querySelector('img');
        button.el.replaceChild(button[newImage], oldImage);
    }

};

//==================================================================================================
//	Map Icon Buttons
//==================================================================================================

DiagnosticTool.prototype.mapIconButtons = function() {
    
    // cache instance
    const diagnosticTool = this;
    
    const iconMap = diagnosticTool.iconButtons.map(function(btn){
        const primaryImage = btn.querySelector('img');
        const secondaryImage = primaryImage.cloneNode();
        secondaryImage.setAttribute('src', primaryImage.dataset.secondarySrc)
        return {
            el: btn,
            primaryImage: primaryImage,
            secondaryImage: secondaryImage
        }
    });

    return iconMap;

};

//==================================================================================================
//	Get Image Object
//==================================================================================================

DiagnosticTool.prototype.getImageObject = function(button) {
    
    // cache instance
    const diagnosticTool = this;

    var imageObject = diagnosticTool.iconArray.find(function(item){
        return item.el === button
    });

    return imageObject;

};