//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { SocialShare } from 'objects/shared/SocialShare';

//==================================================================================================
//
//	Social Share
//
//==================================================================================================
new DOMReady(function initShare() {

    // Get all ahre links on page
    const socialShareElements = toArray(document.querySelectorAll("[data-link='share']"));

    // Check the elements exist
    if(socialShareElements.length > 0) {
        // Loop through all the social share elements
        socialShareElements.forEach(function(currentElement) {
            // Create new social share instance
            const socialShare = new SocialShare(currentElement);
        });
    }

});