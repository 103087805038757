import { toArray } from "helpers/shared/toArray";

export const pagination = (container, slides, instance) => {
    slides = toArray(slides);
    // Cache the carousel instance
    const carousel = instance;

    // Create a fresh array for the carousel dots
    carousel.carouselDots = [];
    
    // If no container just return
    if(!container){
        return;
    }
    // Clear the container
    container.innerHTML = "";

    // Create a templat function
    const createTemplate = (index) => {
        // Create a button element
        const templateBtn = document.createElement("button");
        // Add an attribute to the button
        templateBtn.setAttribute("data-pagination-item", "");
        // Add a click event
        templateBtn.addEventListener("click", (event) => {
            // Prevent default
            event.preventDefault();
            // Go to the index
            carousel.goTo(index);
        });

        // Return
        return templateBtn;
    };

    // Loop through each slide
    slides.forEach((currentSlide, index) => {
        // Create the templat button
        const buttonElement = createTemplate(index);

        // If the button is valid to the num shown
        if((index / carousel.numToSlide) === parseInt(index / carousel.numToSlide) || index  === 0) {
            // Add into the DOM
            container.appendChild(buttonElement);
            // Push to the carousel dots array
            carousel.carouselDots.push(buttonElement);
        }
    });

    if(carousel.numToSlide > 1) {
        // Calculate which pagination item to pic
        const desiredDot = Math.ceil(carousel.currentSlide / carousel.numToSlide);

        // Set the active element
        carousel.carouselDots[desiredDot].setAttribute("data-active", "true");
    }
    else {
        // Set the active element
        carousel.carouselDots[carousel.currentSlide].setAttribute("data-active", "true");
    }

};