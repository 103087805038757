//==================================================================================================
//
//	Check whether an element currently has a class applied
//
//==================================================================================================

if (HTMLElement.hasClass === undefined) {

    /**
     * Check whether an HTMLElement has a particular class
     * @function external:HTMLElement#hasClass
     * @param {String} className - Class to be checked
     * @returns {Boolean}
     * @example
     * let element = document.querySelector(".button");
     * if (element.hasClass("active")) {
     *  //  Do something
     * }
     */
    HTMLElement.prototype.hasClass = function(className) {
        const element = this;
        return element.className.indexOf(className) !== -1;
    };

    /**
     * @function external:SVGElement#hasClass
     * @see external:HTMLElement#hasClass
     */
    SVGElement.prototype.hasClass = HTMLElement.prototype.hasClass;

}