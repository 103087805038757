//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';
import { scrollToElement } from "helpers/scrollToElement";

//==================================================================================================
//	Private Functions
//==================================================================================================
function returnElement(identifier) {
    // Lookup the element
    let element = undefined;

    // Check if data attribute
    if(identifier.includes("data-")) {
        element = document.querySelector(`[${identifier}]`);
    }
    else {
        element = document.querySelector(identifier);
    }

    // Check if result is not valid
    if(element === null || element === undefined) {
        return false;
    }

    // Return Element
    return element;

}

//==================================================================================================
//
//	Scroll To Element Implementation
//
//==================================================================================================
new DOMReady(function initScrollToElement() {
    // Query Select scroll to element
    const scrollToElements = toArray(document.querySelectorAll("[data-scroll-to]"));

    // If elements exist
    if(scrollToElements.length > 0) {
        // Loop through elements
        scrollToElements.forEach(function(currentItem) {

            // Add click event to the element
            currentItem.addEventListener("click", function(event) {
                // Prevent default
                event.preventDefault();

                // Get the dat attibute value
                const target = currentItem.getAttribute("data-scroll-to");

                // Retrive the target element
                const targetElement = returnElement(target);

                // If target is returned
                if(targetElement !== false) {
                    scrollToElement(targetElement);
                }

            });
        });
    }
});