//==================================================================================================
//
//	Delegated event
//
//==================================================================================================
import { DelegatedEvent } from "objects/shared/DelegatedEvent";


//==================================================================================================
//	Attach logic to HTMLElement & SVGElement prototypes
//==================================================================================================
if (HTMLElement.delegate === undefined) {

    /**
     * Delegated events for HTMLElements
     * @function external:HTMLElement#delegate
     * @param {String} eventType - The DOM event type to be delegated
     * @param {String} selector - The DOM selector for matching events
     * @param {String} callback - The event handler to be fired
     * @returns {DelegatedEvent}
     * @example
     * document.body.delegate("click", "button", function buttonClick(event) {
     *  console.log(event);
     * });
     */
    HTMLElement.prototype.delegate = function(eventType, selector, callback) {
        const element = this;
        return new DelegatedEvent(element, eventType, selector, callback);
    };

    /**
     * @function external:SVGElement#delegate
     * @see external:HTMLElement#delegate
     */
    SVGElement.prototype.delegate = HTMLElement.prototype.delegate;

}