//==================================================================================================
//	Dependencies
//==================================================================================================
import anime from "animejs";

//==================================================================================================
//
//	Animate Sub Menu Open
//  This is the function that is called to animate the sub menu open
//
//==================================================================================================

/**
 * Function used to open sub menu
 * @param {Object} instance - The Mega Menu instance to be animated
 * @ignore
 * @example
 * // Cache instance
 * const megaMenu = this;
 *
 * // Set active sub menu
 * megaMenu.activeSubMenu = currentTrayItem;
 *
 * // Open the sub menu
 * openSubMenu(megaMenu);
 */
export const openSubMenu = (instance) => {
    // Cache instance
    const megaMenu = instance;

    // Cache Parts of the object for easy referencing
    const menuItem = megaMenu.activeItem;
    const subMenu = megaMenu.activeSubMenu;

    // Calculate position of trigger from top of the element
    const triggerElementOffsetTop = subMenu.element.offsetTop;

    // Calculate Trigger offset (minus top padding)
    let triggerElementOffset = triggerElementOffsetTop - 10;

    if(triggerElementOffset < 0) { triggerElementOffset = 0; }

    // Define tray items
    const trayItems = [];

    // Filter the array of items so does not contain the active one
    menuItem.tray.items.filter(function(currentTrayItem) {
        // If the current tray item is not the active sub menu
        if(currentTrayItem.element !== subMenu.element) {
            // Add the element to the array
            trayItems.push(currentTrayItem.element);
        }
    });

    // Create timeline
    const timeline = anime.timeline({
        autoplay: false
    });

    // Bump Out Tray Trigger
    timeline.add({
        targets: subMenu.trigger,
        paddingLeft: "30px",
        duration: 200,
        easing: "linear"
    });

    // Check if the sub menu height is more than the container height
    if(subMenu.subMenu.container.offsetHeight > menuItem.tray.containerSize) {
        // Increase the size of the list
        timeline.add({
            targets: menuItem.tray.container,
            height: subMenu.subMenu.container.offsetHeight + 120,
            duration: 200,
            easing: "linear"
        });
    }

    // Animate out tray items
    timeline.add({
        targets: trayItems,
        translateX: "-100%",
        duration: 300,
        easing: "linear",
        offset: "-=100",
        complete: function() {
            // Loop through tray items
            trayItems.forEach(function(currentTrayItem) {
                // Set visibility to 0
                currentTrayItem.style.visibility = "hidden";
            });
        }
    });

    // Reposition Tray trigger
    timeline.add({
        targets: subMenu.element,
        translateY: `-${triggerElementOffset}px`,
        duration: 200,
        easing: "linear",
        complete: function() {
            // set the sub menu to active
            subMenu.element.setAttribute("data-active", "");
        }
    });

    // Animate container out 100%
    timeline.add({
        targets: subMenu.subMenu.container,
        translateX: "100%",
        duration: 0
    });

    // Animate in Sub menu
    timeline.add({
        begin: function() {
            // Update container to visible
            subMenu.subMenu.container.style.visibility = "visible";
            // Set the trigger to the correct position
            subMenu.subMenu.container.style.top = subMenu.trigger.clientHeight + 20 + "px";
        },
        targets: subMenu.subMenu.container,
        translateX: 0,
        opacity: 1,
        duration: 300,
        easing: "linear"
    });

    // Play the timeline
    timeline.play();

};