//==================================================================================================
//	Dependencies
//==================================================================================================
import AOS from 'aos';

//==================================================================================================
//	Once DOM has loaded init AOS
//==================================================================================================

//Need to use load event and setTimeout to get animation to work reliably on chrome
window.addEventListener("load", function () {
    setTimeout(function () {
        AOS.init({
            once: true,
            duration: 800
        });
    }, 1);
});