//==================================================================================================
//	Dependencies
//==================================================================================================
import { toArray } from "helpers/shared/toArray";
import { DOMReady } from 'objects/shared/DOMReady';

//==================================================================================================
//
//	Brand Buttons
//  Fix implemented for brand buttons IOS double click
//
//==================================================================================================
new DOMReady(function initBrandButtons() {

    // Get all buttons
    const buttons = toArray(document.querySelectorAll(".btn"));

    // If buttons exist
    if(buttons.length > 0) {
        // Loop through buttons
        buttons.forEach(function(currentItem) {
            // Add tocuh end event
            currentItem.addEventListener("touchend", function(event) {
                // Capture the event target
                const element = event.target;
                // Activate click
                element.click();
            });
        });
    }

});